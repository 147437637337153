<template>
    <div>
        <div>
        <v-container>
          <v-toolbar flat dense>
            <v-spacer />
            <c-date-picker  
              class="mr-2"
              append-icon="mdi-calendar"
              :label="$t('plannerCodeRange.fromDate')"
              dense outlined  hide-details="auto"
              @input="getPlannerCode()"
              v-model="plannerCodeRange.fromDate">
            </c-date-picker>

             <c-date-picker
              class="mr-2"
              append-icon="mdi-calendar"
              :label="$t('plannerCodeRange.toDate')"
              dense
              outlined
              :max="monthRestriction"
              @input="getPlannerCode()"
              v-model="plannerCodeRange.toDate"
              hide-details="auto"
            ></c-date-picker>

              <v-autocomplete
              outlined
              hide-details="auto"
              dense
              :items="SalesOfficeArr"
              :loading="SalesLoading"
              style="max-width: 25%"
              v-model="SO"
              :label="$t('PlannersInfo.SalesOfficeName')"
              item-text="SalesOfficeData"
              item-value="SalesOfficeCode"
              return-object
              @change="arr.length > 0 ? getPlannerData() : ''"
               />

             <v-autocomplete
              class="ml-2"
              outlined
              maxlength="5"
              dense
              :label="$t('plannerCodeRange.PeriodOfTime')"
              :items="category"
              hide-details="auto"
              v-model="PeriodOftime"
              :item-text="$i18n.locale === 'ja' ? 'jp' : 'en'"
              item-value="en"
              style="max-width: 20%"
              >
            
            </v-autocomplete>

            <v-btn class="ml-2" :style="{ color: 'white' }" :disabled="loading" color="#0D47A1" @click="dialog=true">
              {{$t('plannerCodeRange.DisplayItems')}}
            </v-btn>
            
            <v-btn
              class="ml-2"
              @click="getPlannerData()"
              xxl
              :style="{ color: 'white' }"
              :disabled="loading || (!PeriodOftime || !SO)"
              Length="5"
              color="#0D47A1"
              >{{$t(`generate.generate`)}}</v-btn>
            <v-spacer/>
          </v-toolbar>
        </v-container>
        </div> 

        <div v-if="loading==false">
        <div class="pr-8 pl-8">
        <v-card flat  v-if="data.header">
            <v-simple-table id="tableRef" dense fixed-header :height="plannerData.tableHeight" :style="{'borderBottom': plannerData.scroll? '1px solid black' : ''}">
              <thead >
                <tr>
                  <th>{{ `${$t('PlannersInfo.PlannersCode')}` }}</th>
                  <th> {{ `${$t('PlannersInfo.PlannersName')}`}}</th>
                  <th style="width:25%;">{{ `${$t('PlannersInfo.Item')}` }}</th>
                  <th  colspan="2">{{  `${MomentJP(data.header[0].fromDate)} ~ ${MomentJP(data.header[0].toDate)}` }}</th>
                  <th  colspan="2">{{  `${MomentJP(data.header[1].fromDate)} ~ ${MomentJP(data.header[1].toDate)} ` }}</th>
                  <th  v-if="data.header[2]" colspan="2">{{  `${MomentJP(data.header[2].fromDate)} ~ ${MomentJP(data.header[2].toDate )}` }}</th>
                  <th  v-if="data.header[3]"  colspan="2"> {{ `${MomentJP(data.header[3].fromDate)} ~ ${MomentJP(data.header[3].toDate )}` }}</th>
                </tr>              
              </thead>
              <tbody v-for="(item,i) in arr" :key="i">
                <tr v-if="row.row1 && row.row1 == true">
                  <td  class="bg" v-if="selected[0].title == '加工依頼数'" :rowspan="selected.length">{{ item.EmployeeCode }}</td>
                  <td  class="bg" v-if="selected[0].title == '加工依頼数'" :rowspan="selected.length">{{  item.EmployeeName }}</td>
                  <td class="bg" >{{ '加工依頼数' }}</td> 
                  <td>{{ '-' }}</td>
                  <td>{{ item.data[0] && item.data[0].KakouIrai ? item.data[0].KakouIrai : '-' }}</td>
                  <td >
                    <!-- <v-icon small >
                      {{ item.data[1] && item.data[1].KakouIrai ? icon(getAverage(item.data[1].KakouIrai,item.data[0].KakouIrai)):'' }}
                    </v-icon> -->
                      {{ item.data[1] && item.data[1].KakouIrai ? getAverage(item.data[1].KakouIrai,item.data[0].KakouIrai) +'%' :'' }}
                  </td>
                  <td>{{ item.data[1] && item.data[1].KakouIrai ? item.data[1].KakouIrai : '-' }}</td>
                  
                  <td v-if="Select=='Quarterly'">
                    <!-- <v-icon small >
                      {{ item.data[2] && item.data[2].KakouIrai ? icon(getAverage(item.data[2].KakouIrai,item.data[1].KakouIrai)):'' }}
                    </v-icon> -->
                    {{item.data[2] &&  item.data[2].KakouIrai ? getAverage(item.data[2].KakouIrai,item.data[1].KakouIrai) +'%' :''}}
                  </td>
                  <td v-if="Select=='Quarterly'">{{ item.data[2] && item.data[2].KakouIrai ? item.data[2].KakouIrai :''}}</td>
               
                  <td v-if="Select=='Quarterly'"  >
                    <!-- <v-icon small >
                    {{ item.data[3] && item.data[3].KakouIrai ? icon(getAverage(item.data[3].KakouIrai,item.data[2].KakouIrai)):'' }}
                  </v-icon> -->
                    {{ item.data[3] && item.data[3].KakouIrai ? getAverage(item.data[3].KakouIrai,item.data[2].KakouIrai) +'%':'' }}
                  </td>
                  <td v-if="Select=='Quarterly'">{{ item.data[3] && item.data[3].KakouIrai ? item.data[3].KakouIrai:'' }}</td>
                </tr>
                <tr v-if="row.row2 && row.row2 == true">
                  <td class="bg" v-if="selected[0].title == '追加変更数'" :rowspan="selected.length">{{ item.EmployeeCode }}</td>
                  <td class="bg" v-if="selected[0].title == '追加変更数'" :rowspan="selected.length">{{  item.EmployeeName }}</td>
                  <td class="bg">{{ '追加変更数' }}</td> 
                  <td>{{ '-' }}</td>
                  <td>{{ item.data[0] && item.data[0].TH ?item.data[0].TH :''}}</td>
                  <td :style="item.data[1] && item.data[1].TH  ? color(getAverage(item.data[1].TH,item.data[0].TH)):''">
                    <v-icon small :style=" item.data[1] && item.data[1].TH  ? color(getAverage(item.data[1].TH,item.data[0].TH)):''">
                    {{  item.data[1] && item.data[1].TH  ? icon(getAverage(item.data[1].TH,item.data[0].TH)):'' }}</v-icon>
                    {{  item.data[1] && item.data[1].TH  ? getAverage(item.data[1].TH,item.data[0].TH) +'%':'' }}</td>
                  <td >{{  item.data[1] && item.data[1].TH  ? item.data[1].TH :'' }}</td>
                  <td v-if="Select=='Quarterly'"  :style="item.data[2] && item.data[2].TH ? color(getAverage(item.data[2].TH,item.data[1].TH)) : ''" >
                    <v-icon small :style="item.data[2] && item.data[2].TH ? color(getAverage(item.data[2].TH,item.data[1].TH)) :''">
                    {{ item.data[2] && item.data[2].TH ? icon(getAverage(item.data[2].TH,item.data[1].TH)):'' }}</v-icon>
                    {{ item.data[2] && item.data[2].TH ? getAverage(item.data[2].TH,item.data[1].TH) +'%' :''}}</td>
                  <td v-if="Select=='Quarterly'">{{ item.data[2] && item.data[2].TH ? item.data[2].TH :''}}</td>
                  <td v-if="Select=='Quarterly'"  :style="item.data[3] && item.data[3].TH  ? color(getAverage(item.data[3].TH,item.data[2].TH)) : ''" >
                    <v-icon small :style="item.data[3] && item.data[3].TH  ?color(getAverage(item.data[3].TH,item.data[2].TH)) : ''">
                    {{item.data[3] && item.data[3].TH  ?  icon(getAverage(item.data[3].TH,item.data[2].TH)) :''}}</v-icon>
                    {{ item.data[3] && item.data[3].TH  ? getAverage(item.data[3].TH,item.data[2].TH) +'%':'' }}</td>
                  <td v-if="Select=='Quarterly'" >{{ item.data[3] && item.data[3].TH  ? item.data[3].TH :''}}</td>
                </tr>
                <tr v-if="row.row3 && row.row3 == true">
                  <td  class="bg" v-if="selected[0].title == '1件あたりの追加変更数'" :rowspan="selected.length">{{ item.EmployeeCode }}</td>
                  <td  class="bg" v-if="selected[0].title == '1件あたりの追加変更数'" :rowspan="selected.length">{{  item.EmployeeName }}</td>
                  <td class="bg" >{{ '1件あたりの追加変更数' }}</td> 
                  <td>{{ '-' }}</td>
                  <td>{{ item.data[0] && item.data[0].THavg ? item.data[0].THavg :'' }}</td>
                  <td :style="item.data[1] && item.data[1].THavg ? color(getAverage( item.data[1].THavg ,item.data[0].THavg)):''">
                    <v-icon small :style="item.data[1] && item.data[1].THavg ? color(getAverage( item.data[1].THavg ,item.data[0].THavg)):''">
                    {{item.data[1] && item.data[1].THavg ? icon(getAverage( item.data[1].THavg ,item.data[0].THavg)) :''}}</v-icon>
                    {{ item.data[1] && item.data[1].THavg ?getAverage( item.data[1].THavg ,item.data[0].THavg) +'%' :''}}</td>
                  <td>{{ item.data[1] && item.data[1].THavg ? item.data[1].THavg :''}}</td>
                  <td v-if="Select=='Quarterly'"  :style="item.data[2] && item.data[2].THavg ? color(getAverage( item.data[2].THavg ,item.data[1].THavg)) :''">
                  <v-icon small :style="item.data[2] && item.data[2].THavg ? color(getAverage( item.data[2].THavg ,item.data[1].THavg)) :''">
                  {{ item.data[2] && item.data[2].THavg ? icon(getAverage( item.data[2].THavg ,item.data[1].THavg))  :''}}</v-icon>
                  {{ item.data[2] && item.data[2].THavg ? getAverage( item.data[2].THavg ,item.data[1].THavg) +'%' :'' }}</td>
                  <td v-if="Select=='Quarterly'" >{{ item.data[2] && item.data[2].THavg ? item.data[2].THavg :''  }}</td>
                  <td  v-if="Select=='Quarterly'" :style="item.data[3] && item.data[3].THavg ? color(getAverage( item.data[3].THavg ,item.data[2].THavg)):''">
                  <v-icon small :style="item.data[3] && item.data[3].THavg ? color(getAverage( item.data[3].THavg ,item.data[2].THavg)):''">
                    {{ item.data[3] && item.data[3].THavg ? icon(getAverage( item.data[3].THavg ,item.data[2].THavg)) :''}}</v-icon>
                    {{ item.data[3] && item.data[3].THavg ? getAverage(item.data[3].THavg ,item.data[2].THavg) +'%' :''}}
                  </td>
                  <td v-if="Select=='Quarterly'" >{{ item.data[3] && item.data[3].THavg ? item.data[3].THavg  :''}}</td>
                </tr>
                <tr v-if="row.row4 && row.row4 == true">
                  <td  class="bg" v-if="selected[0].title == 'CAD_依頼回数平均'" :rowspan="selected.length">{{item.EmployeeCode}}</td>
                  <td  class="bg" v-if="selected[0].title == 'CAD_依頼回数平均'" :rowspan="selected.length">{{item.EmployeeName}}</td>
                  <td class="bg" >{{ 'CAD_依頼回数平均' }}</td> 
                  <td>{{ '-' }}</td> 
                  <td>{{ item.data[0] && item.data[0].CadAvg ?  item.data[0].CadAvg  : '' }}</td>
                  <td :style="item.data[1] && item.data[1].CadAvg  ? color(getAverage(item.data[1].CadAvg,item.data[0].CadAvg)):''">
                    
                    <v-icon small :style="item.data[1] && item.data[1].CadAvg  ? color(getAverage(item.data[1].CadAvg,item.data[0].CadAvg)):''">
                    {{ item.data[1] && item.data[1].CadAvg ? icon(getAverage(item.data[1].CadAvg,item.data[0].CadAvg)) :''}}</v-icon>
                    {{ item.data[1] && item.data[1].CadAvg  ? getAverage(item.data[1].CadAvg,item.data[0].CadAvg) +'%':'' }}</td>
                  <td>{{ item.data[1] && item.data[1].CadAvg ? item.data[1].CadAvg :'' }}</td>
                  
                  <td v-if="Select=='Quarterly'"  :style=" item.data[2] && item.data[2].CadAvg ? color(getAverage(item.data[2].CadAvg,item.data[1].CadAvg)):''" >
                    <v-icon small :style=" item.data[2] && item.data[2].CadAvg ? color(getAverage(item.data[2].CadAvg,item.data[1].CadAvg)):''">
                    {{ item.data[2] && item.data[2].CadAvg ? icon(getAverage(item.data[2].CadAvg,item.data[1].CadAvg)):'' }}</v-icon>
                    {{ item.data[2] && item.data[2].CadAvg ? getAverage(item.data[2].CadAvg,item.data[1].CadAvg) +'%':'' }}</td>
                  <td v-if="Select=='Quarterly'" >{{ item.data[2] && item.data[2].CadAvg ? item.data[2].CadAvg :''}}</td>
                  
                  <td v-if="Select=='Quarterly'"  :style="item.data[3] && item.data[3].CadAvg ? color(getAverage(item.data[3].CadAvg,item.data[2].CadAvg)):''" >
                    <v-icon small :style="item.data[3] && item.data[3].CadAvg ? color(getAverage(item.data[3].CadAvg,item.data[2].CadAvg)):''">
                    {{ item.data[3] && item.data[3].CadAvg ? icon(getAverage(item.data[3].CadAvg,item.data[2].CadAvg)) :''}}</v-icon>
                    {{ item.data[3] && item.data[3].CadAvg ? getAverage(item.data[3].CadAvg,item.data[2].CadAvg) +'%' :''}}</td>
                 
                    <td v-if="Select=='Quarterly'" >{{ item.data[3] && item.data[3].CadAvg ? item.data[3].CadAvg :''}}</td>
                </tr>
                <tr v-if="row.row5 && row.row5 == true">
                  <td  class="bg" v-if="selected[0].title == '即日CAD_依頼数平均'" :rowspan="selected.length">{{item.EmployeeCode}}</td>
                  <td  class="bg" v-if="selected[0].title == '即日CAD_依頼数平均'" :rowspan="selected.length">{{item.EmployeeName}}</td>
                  <td class="bg" >{{ '即日CAD_依頼数平均' }}</td> 
                  <td>{{ '-' }}</td> 
                  <td>{{ item.data[0] && item.data[0].CadExpressAvg ?  item.data[0].CadExpressAvg  : '' }}</td>
                  <td :style="item.data[1] && item.data[1].CadExpressAvg  ? color(getAverage(item.data[1].CadExpressAvg,item.data[0].CadExpressAvg)):''">
                    
                    <v-icon small :style="item.data[1] && item.data[1].CadExpressAvg  ? color(getAverage(item.data[1].CadExpressAvg,item.data[0].CadExpressAvg)):''">
                    {{ item.data[1] && item.data[1].CadExpressAvg ? icon(getAverage(item.data[1].CadExpressAvg,item.data[0].CadExpressAvg)) :''}}</v-icon>
                    {{ item.data[1] && item.data[1].CadExpressAvg  ? getAverage(item.data[1].CadExpressAvg,item.data[0].CadExpressAvg) +'%':'' }}</td>
                  <td>{{ item.data[1] && item.data[1].CadExpressAvg ? item.data[1].CadExpressAvg :'' }}</td>
                  
                  <td v-if="Select=='Quarterly'"  :style=" item.data[2] && item.data[2].CadExpressAvg ? color(getAverage(item.data[2].CadExpressAvg,item.data[1].CadExpressAvg)):''" >
                    <v-icon small :style=" item.data[2] && item.data[2].CadExpressAvg ? color(getAverage(item.data[2].CadExpressAvg,item.data[1].CadExpressAvg)):''">
                    {{ item.data[2] && item.data[2].CadExpressAvg ? icon(getAverage(item.data[2].CadExpressAvg,item.data[1].CadExpressAvg)):'' }}</v-icon>
                    {{ item.data[2] && item.data[2].CadExpressAvg ? getAverage(item.data[2].CadExpressAvg,item.data[1].CadExpressAvg) +'%':'' }}</td>
                  <td v-if="Select=='Quarterly'" >{{ item.data[2] && item.data[2].CadExpressAvg ? item.data[2].CadExpressAvg :''}}</td>
                  
                  <td v-if="Select=='Quarterly'"  :style="item.data[3] && item.data[3].CadExpressAvg ? color(getAverage(item.data[3].CadExpressAvg,item.data[2].CadExpressAvg)):''" >
                    <v-icon small :style="item.data[3] && item.data[3].CadExpressAvg ? color(getAverage(item.data[3].CadExpressAvg,item.data[2].CadExpressAvg)):''">
                    {{ item.data[3] && item.data[3].CadExpressAvg ? icon(getAverage(item.data[3].CadExpressAvg,item.data[2].CadExpressAvg)) :''}}</v-icon>
                    {{ item.data[3] && item.data[3].CadExpressAvg ? getAverage(item.data[3].CadExpressAvg,item.data[2].CadExpressAvg) +'%' :''}}</td>
                 
                    <td v-if="Select=='Quarterly'" >{{ item.data[3] && item.data[3].CadExpressAvg ? item.data[3].CadExpressAvg :''}}</td>
                </tr>
                <tr v-if="row.row7 && row.row7 == true">
                  <td  class="bg" v-if="selected[0].title == '提案電気図面依頼以降'" :rowspan="selected.length">{{item.EmployeeCode}}</td>
                  <td  class="bg" v-if="selected[0].title == '提案電気図面依頼以降'" :rowspan="selected.length">{{item.EmployeeName}}</td>
                  <td class="bg" >{{ '提案電気図面依頼以降' }}</td> 
                  <td>{{ '-' }}</td> 
                  <td>{{ item.data[0] && item.data[0].CadPdrAvg ?  item.data[0].CadPdrAvg  : '' }}</td>
                  <td :style="item.data[1] && item.data[1].CadPdrAvg  ? color(getAverage(item.data[1].CadPdrAvg,item.data[0].CadPdrAvg)):''">
                    
                    <v-icon small :style="item.data[1] && item.data[1].CadPdrAvg  ? color(getAverage(item.data[1].CadPdrAvg,item.data[0].CadPdrAvg)):''">
                    {{ item.data[1] && item.data[1].CadPdrAvg ? icon(getAverage(item.data[1].CadPdrAvg,item.data[0].CadPdrAvg)) :''}}</v-icon>
                    {{ item.data[1] && item.data[1].CadPdrAvg  ? getAverage(item.data[1].CadPdrAvg,item.data[0].CadPdrAvg) +'%':'' }}</td>
                  <td>{{ item.data[1] && item.data[1].CadPdrAvg ? item.data[1].CadPdrAvg :'' }}</td>
                  
                  <td v-if="Select=='Quarterly'"  :style=" item.data[2] && item.data[2].CadPdrAvg ? color(getAverage(item.data[2].CadPdrAvg,item.data[1].CadPdrAvg)):''" >
                    <v-icon small :style=" item.data[2] && item.data[2].CadPdrAvg ? color(getAverage(item.data[2].CadPdrAvg,item.data[1].CadPdrAvg)):''">
                    {{ item.data[2] && item.data[2].CadPdrAvg ? icon(getAverage(item.data[2].CadPdrAvg,item.data[1].CadPdrAvg)):'' }}</v-icon>
                    {{ item.data[2] && item.data[2].CadPdrAvg ? getAverage(item.data[2].CadPdrAvg,item.data[1].CadPdrAvg) +'%':'' }}</td>
                  <td v-if="Select=='Quarterly'" >{{ item.data[2] && item.data[2].CadPdrAvg ? item.data[2].CadPdrAvg :''}}</td>
                  
                  <td v-if="Select=='Quarterly'"  :style="item.data[3] && item.data[3].CadPdrAvg ? color(getAverage(item.data[3].CadPdrAvg,item.data[2].CadPdrAvg)):''" >
                    <v-icon small :style="item.data[3] && item.data[3].CadPdrAvg ? color(getAverage(item.data[3].CadPdrAvg,item.data[2].CadPdrAvg)):''">
                    {{ item.data[3] && item.data[3].CadPdrAvg ? icon(getAverage(item.data[3].CadPdrAvg,item.data[2].CadPdrAvg)) :''}}</v-icon>
                    {{ item.data[3] && item.data[3].CadPdrAvg ? getAverage(item.data[3].CadPdrAvg,item.data[2].CadPdrAvg) +'%' :''}}</td>
                 
                    <td v-if="Select=='Quarterly'" >{{ item.data[3] && item.data[3].CadPdrAvg ? item.data[3].CadPdrAvg :''}}</td>
                </tr>
                <tr v-if="row.row8 && row.row8 == true">
                  <td  class="bg" v-if="selected[0].title == '着手図面依頼以降'" :rowspan="selected.length">{{item.EmployeeCode}}</td>
                  <td  class="bg" v-if="selected[0].title == '着手図面依頼以降'" :rowspan="selected.length">{{item.EmployeeName}}</td>
                  <td class="bg" >{{ '着手図面依頼以降' }}</td> 
                  <td>{{ '-' }}</td> 
                  <td>{{ item.data[0] && item.data[0].CadFinalAvg ?  item.data[0].CadFinalAvg  : '' }}</td>
                  <td :style="item.data[1] && item.data[1].CadFinalAvg  ? color(getAverage(item.data[1].CadFinalAvg,item.data[0].CadFinalAvg)):''">
                    
                    <v-icon small :style="item.data[1] && item.data[1].CadFinalAvg  ? color(getAverage(item.data[1].CadFinalAvg,item.data[0].CadFinalAvg)):''">
                    {{ item.data[1] && item.data[1].CadFinalAvg ? icon(getAverage(item.data[1].CadFinalAvg,item.data[0].CadFinalAvg)) :''}}</v-icon>
                    {{ item.data[1] && item.data[1].CadFinalAvg  ? getAverage(item.data[1].CadFinalAvg,item.data[0].CadFinalAvg) +'%':'' }}</td>
                  <td>{{ item.data[1] && item.data[1].CadFinalAvg ? item.data[1].CadFinalAvg :'' }}</td>
                  
                  <td v-if="Select=='Quarterly'"  :style=" item.data[2] && item.data[2].CadFinalAvg ? color(getAverage(item.data[2].CadFinalAvg,item.data[1].CadFinalAvg)):''" >
                    <v-icon small :style=" item.data[2] && item.data[2].CadFinalAvg ? color(getAverage(item.data[2].CadFinalAvg,item.data[1].CadFinalAvg)):''">
                    {{ item.data[2] && item.data[2].CadFinalAvg ? icon(getAverage(item.data[2].CadFinalAvg,item.data[1].CadFinalAvg)):'' }}</v-icon>
                    {{ item.data[2] && item.data[2].CadFinalAvg ? getAverage(item.data[2].CadFinalAvg,item.data[1].CadFinalAvg) +'%':'' }}</td>
                  <td v-if="Select=='Quarterly'" >{{ item.data[2] && item.data[2].CadFinalAvg ? item.data[2].CadFinalAvg :''}}</td>
                  
                  <td v-if="Select=='Quarterly'"  :style="item.data[3] && item.data[3].CadFinalAvg ? color(getAverage(item.data[3].CadFinalAvg,item.data[2].CadFinalAvg)):''" >
                    <v-icon small :style="item.data[3] && item.data[3].CadFinalAvg ? color(getAverage(item.data[3].CadFinalAvg,item.data[2].CadFinalAvg)):''">
                    {{ item.data[3] && item.data[3].CadFinalAvg ? icon(getAverage(item.data[3].CadFinalAvg,item.data[2].CadFinalAvg)) :''}}</v-icon>
                    {{ item.data[3] && item.data[3].CadFinalAvg ? getAverage(item.data[3].CadFinalAvg,item.data[2].CadFinalAvg) +'%' :''}}</td>
                 
                    <td v-if="Select=='Quarterly'" >{{ item.data[3] && item.data[3].CadFinalAvg ? item.data[3].CadFinalAvg :''}}</td>
                </tr>
                <tr v-if="row.row9 && row.row9 == true">
                  <td  class="bg" v-if="selected[0].title == '電気図面_依頼回数平均'" :rowspan="selected.length">{{ item.EmployeeCode }}</td>
                  <td  class="bg" v-if="selected[0].title == '電気図面_依頼回数平均'" :rowspan="selected.length">{{  item.EmployeeName }}</td>
                  <td class="bg" >{{ '電気図面_依頼回数平均' }}</td> 
                  <td>{{ '-' }}</td>
                  <td>{{ item.data[0] && item.data[0].DenkiAvg ? item.data[0].DenkiAvg:''}}</td>
                  <td :style="item.data[1] && item.data[1].DenkiAvg  ? color(getAverage(item.data[1].DenkiAvg,item.data[0].DenkiAvg)):''">
                    <v-icon small :style="item.data[1] && item.data[1].DenkiAvg  ? color(getAverage(item.data[1].DenkiAvg,item.data[0].DenkiAvg)):''">
                    {{ item.data[1] && item.data[1].DenkiAvg  ? icon(getAverage(item.data[1].DenkiAvg,item.data[0].DenkiAvg)):'' }}</v-icon>
                    {{ item.data[1] && item.data[1].DenkiAvg  ? getAverage(item.data[1].DenkiAvg,item.data[0].DenkiAvg) +'%':'' }}</td>
                  <td>{{ item.data[1] && item.data[1].DenkiAvg  ? item.data[1].DenkiAvg :'' }}</td>
                  <td v-if="Select=='Quarterly'" :style="item.data[2] && item.data[2].DenkiAvg ?  color(getAverage(item.data[2].DenkiAvg,item.data[1].DenkiAvg)):''" >
                    <v-icon small :style="item.data[2] && item.data[2].DenkiAvg ? color(getAverage(item.data[2].DenkiAvg,item.data[1].DenkiAvg)):''">
                    {{item.data[2] && item.data[2].DenkiAvg ? icon(getAverage(item.data[2].DenkiAvg,item.data[1].DenkiAvg)):'' }}</v-icon>
                    {{ item.data[2] && item.data[2].DenkiAvg ? getAverage(item.data[2].DenkiAvg,item.data[1].DenkiAvg) +'%' :''}}</td>
                  <td v-if="Select=='Quarterly'">{{ item.data[2] && item.data[2].DenkiAvg ? item.data[2].DenkiAvg :''}}</td>
                  <td v-if="Select=='Quarterly'" :style="item.data[3] && item.data[3].DenkiAvg  ? color(getAverage(item.data[3].DenkiAvg,item.data[2].DenkiAvg)):''" >
                    <v-icon small :style="item.data[3] && item.data[3].DenkiAvg  ? color(getAverage(item.data[3].DenkiAvg,item.data[2].DenkiAvg)):''">
                    {{item.data[3] && item.data[3].DenkiAvg  ?  icon(getAverage(item.data[3].DenkiAvg,item.data[2].DenkiAvg)):'' }}</v-icon>
                    {{ item.data[3] && item.data[3].DenkiAvg  ? getAverage(item.data[3].DenkiAvg,item.data[2].DenkiAvg) +'%' :''}}</td>
                  <td v-if="Select=='Quarterly'">{{ item.data[3] && item.data[3].DenkiAvg  ? item.data[3].DenkiAvg :''}}</td>
                </tr>
                <tr v-if="row.row10 && row.row10 == true">
                  <td  class="bg" v-if="selected[0].title == '即日電気_依頼数平均'" :rowspan="selected.length">{{ item.EmployeeCode }}</td>
                  <td  class="bg" v-if="selected[0].title == '即日電気_依頼数平均'" :rowspan="selected.length">{{  item.EmployeeName }}</td>
                  <td class="bg" >{{ '即日電気_依頼数平均' }}</td> 
                  <td>{{ '-' }}</td>
                  <td>{{ item.data[0] && item.data[0].DenkiExpressAvg ? item.data[0].DenkiExpressAvg:''}}</td>
                  <td :style="item.data[1] && item.data[1].DenkiExpressAvg  ? color(getAverage(item.data[1].DenkiExpressAvg,item.data[0].DenkiExpressAvg)):''">
                    <v-icon small :style="item.data[1] && item.data[1].DenkiExpressAvg  ? color(getAverage(item.data[1].DenkiExpressAvg,item.data[0].DenkiExpressAvg)):''">
                    {{ item.data[1] && item.data[1].DenkiExpressAvg  ? icon(getAverage(item.data[1].DenkiExpressAvg,item.data[0].DenkiExpressAvg)):'' }}</v-icon>
                    {{ item.data[1] && item.data[1].DenkiExpressAvg  ? getAverage(item.data[1].DenkiExpressAvg,item.data[0].DenkiExpressAvg) +'%':'' }}</td>
                  <td>{{ item.data[1] && item.data[1].DenkiExpressAvg  ? item.data[1].DenkiExpressAvg :'' }}</td>
                  <td v-if="Select=='Quarterly'" :style="item.data[2] && item.data[2].DenkiExpressAvg ?  color(getAverage(item.data[2].DenkiExpressAvg,item.data[1].DenkiExpressAvg)):''" >
                    <v-icon small :style="item.data[2] && item.data[2].DenkiExpressAvg ? color(getAverage(item.data[2].DenkiExpressAvg,item.data[1].DenkiExpressAvg)):''">
                    {{item.data[2] && item.data[2].DenkiExpressAvg ? icon(getAverage(item.data[2].DenkiExpressAvg,item.data[1].DenkiExpressAvg)):'' }}</v-icon>
                    {{ item.data[2] && item.data[2].DenkiExpressAvg ? getAverage(item.data[2].DenkiExpressAvg,item.data[1].DenkiExpressAvg) +'%' :''}}</td>
                  <td v-if="Select=='Quarterly'">{{ item.data[2] && item.data[2].DenkiExpressAvg ? item.data[2].DenkiExpressAvg :''}}</td>
                  <td v-if="Select=='Quarterly'" :style="item.data[3] && item.data[3].DenkiExpressAvg  ? color(getAverage(item.data[3].DenkiExpressAvg,item.data[2].DenkiExpressAvg)):''" >
                    <v-icon small :style="item.data[3] && item.data[3].DenkiExpressAvg  ? color(getAverage(item.data[3].DenkiExpressAvg,item.data[2].DenkiExpressAvg)):''">
                    {{item.data[3] && item.data[3].DenkiExpressAvg  ?  icon(getAverage(item.data[3].DenkiExpressAvg,item.data[2].DenkiExpressAvg)):'' }}</v-icon>
                    {{ item.data[3] && item.data[3].DenkiExpressAvg  ? getAverage(item.data[3].DenkiExpressAvg,item.data[2].DenkiExpressAvg) +'%' :''}}</td>
                  <td v-if="Select=='Quarterly'">{{ item.data[3] && item.data[3].DenkiExpressAvg  ? item.data[3].DenkiExpressAvg :''}}</td>
                </tr>
                <tr v-if="row.row11 && row.row11 == true">
                  <td  class="bg" v-if="selected[0].title == 'カラーパース_依頼回数平均'" :rowspan="selected.length">{{ item.EmployeeCode }}</td>
                  <td  class="bg" v-if="selected[0].title == 'カラーパース_依頼回数平均'" :rowspan="selected.length">{{  item.EmployeeName }}</td>
                  <td class="bg" >{{ 'カラーパース_依頼回数平均' }}</td> 
                  <td>{{ '-' }}</td> 
                  <td>{{ item.data[0] && item.data[0].ExternalPersAvg?item.data[0].ExternalPersAvg:"" }}</td>
                  <td :style="item.data[1] && item.data[1].ExternalPersAvg  ? color(getAverage(item.data[1].ExternalPersAvg,item.data[0].ExternalPersAvg)):''">
                    <v-icon small :style="item.data[1] && item.data[1].ExternalPersAvg  ? color(getAverage(item.data[1].ExternalPersAvg,item.data[0].ExternalPersAvg)):''">
                    {{item.data[1] && item.data[1].ExternalPersAvg  ?  icon(getAverage(item.data[1].ExternalPersAvg,item.data[0].ExternalPersAvg)) :''}}</v-icon>
                    {{ item.data[1] && item.data[1].ExternalPersAvg  ? getAverage(item.data[1].ExternalPersAvg,item.data[0].ExternalPersAvg) +'%' :''}}
                  </td>
                  <td>{{ item.data[1] && item.data[1].ExternalPersAvg  ? item.data[1].ExternalPersAvg :'' }}</td>
                  <td v-if="Select=='Quarterly'" :style="item.data[2] && item.data[2].ExternalPersAvg  ? color(getAverage(item.data[2].ExternalPersAvg,item.data[1].ExternalPersAvg)):''" >
                    <v-icon small :style="item.data[2] && item.data[2].ExternalPersAvg  ? color(getAverage(item.data[2].ExternalPersAvg,item.data[1].ExternalPersAvg)):''">
                    {{ item.data[2] && item.data[2].ExternalPersAvg  ? icon(getAverage(item.data[2].ExternalPersAvg,item.data[1].ExternalPersAvg)):'' }}</v-icon>
                    {{ item.data[2] && item.data[2].ExternalPersAvg  ? getAverage(item.data[2].ExternalPersAvg,item.data[1].ExternalPersAvg) +'%':'' }}</td>
                  <td v-if="Select=='Quarterly'">{{ item.data[2] && item.data[2].ExternalPersAvg  ? item.data[2].ExternalPersAvg :''}}</td>
                  <td v-if="Select=='Quarterly'" :style="item.data[3] && item.data[3].ExternalPersAvg  ? color(getAverage(item.data[3].ExternalPersAvg,item.data[2].ExternalPersAvg)):''" >
                    <v-icon small :style="item.data[3] && item.data[3].ExternalPersAvg  ? color(getAverage(item.data[3].ExternalPersAvg,item.data[2].ExternalPersAvg)):''">
                    {{ item.data[3] && item.data[3].ExternalPersAvg  ? icon(getAverage(item.data[3].ExternalPersAvg,item.data[2].ExternalPersAvg)):'' }}</v-icon>
                    {{ item.data[3] && item.data[3].ExternalPersAvg  ? getAverage(item.data[3].ExternalPersAvg,item.data[2].ExternalPersAvg) +'%':'' }}</td>
                  <td v-if="Select=='Quarterly'">{{ item.data[3] && item.data[3].ExternalPersAvg  ? item.data[3].ExternalPersAvg :''}}</td>
                </tr>
                <tr v-if="row.row12 && row.row12 == true">
                  <td  class="bg" v-if="selected[0].title == 'HP_依頼回数平均'" :rowspan="selected.length">{{item.EmployeeCode }}</td>
                  <td  class="bg" v-if="selected[0].title == 'HP_依頼回数平均'" :rowspan="selected.length">{{item.EmployeeName }}</td>
                  <td class="bg" >{{ 'HP_依頼回数平均' }}</td> 
                  <td>{{ '-' }}</td>  
                  <td>{{item.data[0] && item.data[0].HPAvg ? item.data[0].HPAvg :''}}</td>
                  <td :style="item.data[1] && item.data[1].HPAvg  ? color(getAverage(item.data[1].HPAvg,item.data[0].HPAvg)):''">
                    <v-icon small :style="item.data[1] && item.data[1].HPAvg  ? color(getAverage(item.data[1].HPAvg,item.data[0].HPAvg)):''">
                    {{ item.data[1] && item.data[1].HPAvg  ? icon(getAverage(item.data[1].HPAvg,item.data[0].HPAvg)):'' }}</v-icon>
                    {{ item.data[1] && item.data[1].HPAvg  ? getAverage(item.data[1].HPAvg,item.data[0].HPAvg) +'%' :''}}
                  </td>
                  <td>{{ item.data[1] && item.data[1].HPAvg  ? item.data[1].HPAvg :'' }}</td>
                  <td v-if="Select=='Quarterly'" :style=" item.data[2] && item.data[2].HPAvg? color(getAverage(item.data[2].HPAvg,item.data[1].HPAvg)):''" >
                    <v-icon small :style=" item.data[2] && item.data[2].HPAvg? color(getAverage(item.data[2].HPAvg,item.data[1].HPAvg)):''">
                    {{  item.data[2] && item.data[2].HPAvg? icon(getAverage(item.data[2].HPAvg,item.data[1].HPAvg)):'' }}</v-icon>
                    {{  item.data[2] && item.data[2].HPAvg? getAverage(item.data[2].HPAvg,item.data[1].HPAvg) +'%':'' }}</td>
                  <td v-if="Select=='Quarterly'">{{  item.data[2] && item.data[2].HPAvg? item.data[2].HPAvg :''}}</td>
                  <td v-if="Select=='Quarterly'" :style="item.data[3] && item.data[3].HPAvg ? color(getAverage(item.data[3].HPAvg,item.data[2].HPAvg)):''" >
                    <v-icon small :style="item.data[3] && item.data[3].HPAvg ? color(getAverage(item.data[3].HPAvg,item.data[2].HPAvg)):''">
                    {{ item.data[3] && item.data[3].HPAvg ? icon(getAverage(item.data[3].HPAvg,item.data[2].HPAvg)):'' }}</v-icon>
                    {{ item.data[3] && item.data[3].HPAvg ? getAverage(item.data[3].HPAvg,item.data[2].HPAvg) +'%':'' }}</td>
                  <td v-if="Select=='Quarterly'">{{ item.data[3] && item.data[3].HPAvg ? item.data[3].HPAvg :''}}</td>
                </tr>
                <tr v-if="row.row13 && row.row13 == true">
                  <td  class="bg" v-if="selected[0].title == '構造事前検討（枠）_依頼回数平均'" :rowspan="selected.length">{{item.EmployeeCode }}</td>
                  <td  class="bg" v-if="selected[0].title == '構造事前検討（枠）_依頼回数平均'" :rowspan="selected.length">{{item.EmployeeName }}</td>
                  <td class="bg" >{{ '構造事前検討（枠）_依頼回数平均' }}</td> 
                  <td>{{ '-' }}</td> 
                  <td>{{ item.data[0] && item.data[0].SI_WakuAvg ? item.data[0].SI_WakuAvg:'' }}</td>
                  <td :style="item.data[1] && item.data[1].SI_WakuAvg ? color(getAverage(item.data[1].SI_WakuAvg,item.data[0].SI_WakuAvg)):''">
                    <v-icon small :style="item.data[1] && item.data[1].SI_WakuAvg ? color(getAverage(item.data[1].SI_WakuAvg,item.data[0].SI_WakuAvg)):''">
                    {{ item.data[1] && item.data[1].SI_WakuAvg ? icon(getAverage(item.data[1].SI_WakuAvg,item.data[0].SI_WakuAvg)):'' }}</v-icon>
                    {{ item.data[1] && item.data[1].SI_WakuAvg ? getAverage(item.data[1].SI_WakuAvg,item.data[0].SI_WakuAvg) +'%' :''}}
                  </td>
                  <td>{{ item.data[1] && item.data[1].SI_WakuAvg ? item.data[1].SI_WakuAvg :'' }}</td>
                  <td v-if="Select=='Quarterly'" :style=" item.data[2] && item.data[2].SI_WakuAvg ? color(getAverage(item.data[2].SI_WakuAvg,item.data[1].SI_WakuAvg)):''" >
                    <v-icon small :style=" item.data[2] && item.data[2].SI_WakuAvg ? color(getAverage(item.data[2].SI_WakuAvg,item.data[1].SI_WakuAvg)):''">
                    {{  item.data[2] && item.data[2].SI_WakuAvg ? icon(getAverage(item.data[2].SI_WakuAvg,item.data[1].SI_WakuAvg)):'' }}</v-icon>
                    {{  item.data[2] && item.data[2].SI_WakuAvg ? getAverage(item.data[2].SI_WakuAvg,item.data[1].SI_WakuAvg) +'%':'' }}</td>
                  <td v-if="Select=='Quarterly'">{{  item.data[2] && item.data[2].SI_WakuAvg ? item.data[2].SI_WakuAvg :''}}</td>
                  <td v-if="Select=='Quarterly'" :style="item.data[3] && item.data[3].SI_WakuAvg ? color(getAverage(item.data[3].SI_WakuAvg,item.data[2].SI_WakuAvg)):''" >
                    <v-icon small :style="item.data[3] && item.data[3].SI_WakuAvg ? color(getAverage(item.data[3].SI_WakuAvg,item.data[2].SI_WakuAvg)):''">
                    {{ item.data[3] && item.data[3].SI_WakuAvg ? icon(getAverage(item.data[3].SI_WakuAvg,item.data[2].SI_WakuAvg)) :''}}</v-icon>
                    {{ item.data[3] && item.data[3].SI_WakuAvg ? getAverage(item.data[3].SI_WakuAvg,item.data[2].SI_WakuAvg) +'%' :''}}</td>
                  <td v-if="Select=='Quarterly'">{{ item.data[3] && item.data[3].SI_WakuAvg ? item.data[3].SI_WakuAvg :''}}</td>
                </tr>
                <tr v-if="row.row14 && row.row14 == true">
                  <td  class="bg" v-if="selected[0].title == '構造事前検討（軸）_依頼回数平均'" :rowspan="selected.length ">{{ item.EmployeeCode }}</td>
                  <td  class="bg" v-if="selected[0].title == '構造事前検討（軸）_依頼回数平均'" :rowspan="selected.length   ">{{  item.EmployeeName }}</td>
                  <td class="bg" >{{ '構造事前検討（軸）_依頼回数平均' }}</td> 
                  <td>{{ '-' }}</td> 
                  <td>{{ item.data[0] && item.data[0].SI_JikuAvg ? item.data[0].SI_JikuAvg:''}}</td>
                  <td :style=" item.data[1] && item.data[1].SI_JikuAvg ? color(getAverage(item.data[1].SI_JikuAvg,item.data[0].SI_JikuAvg)):''">
                    <v-icon small :style=" item.data[1] && item.data[1].SI_JikuAvg ? color(getAverage(item.data[1].SI_JikuAvg,item.data[0].SI_JikuAvg)):''">
                    {{  item.data[1] && item.data[1].SI_JikuAvg ? icon(getAverage(item.data[1].SI_JikuAvg,item.data[0].SI_JikuAvg)) :''}}</v-icon>
                    {{  item.data[1] && item.data[1].SI_JikuAvg ? getAverage(item.data[1].SI_JikuAvg,item.data[0].SI_JikuAvg) +'%':'' }}
                  </td>
                  <td>{{  item.data[1] && item.data[1].SI_JikuAvg ? item.data[1].SI_JikuAvg :'' }}</td>
                  <td v-if="Select=='Quarterly'" :style="item.data[2] && item.data[2].SI_JikuAvg ? color(getAverage(item.data[2].SI_JikuAvg,item.data[1].SI_JikuAvg)):''" >
                    <v-icon small :style="item.data[2] && item.data[2].SI_JikuAvg ? color(getAverage(item.data[2].SI_JikuAvg,item.data[1].SI_JikuAvg)):''">
                    {{ item.data[2] && item.data[2].SI_JikuAvg ? icon(getAverage(item.data[2].SI_JikuAvg,item.data[1].SI_JikuAvg)):'' }}</v-icon>
                    {{ item.data[2] && item.data[2].SI_JikuAvg ? getAverage(item.data[2].SI_JikuAvg,item.data[1].SI_JikuAvg) +'%':'' }}</td>
                  <td v-if="Select=='Quarterly'">{{ item.data[2] && item.data[2].SI_JikuAvg ? item.data[2].SI_JikuAvg:'' }}</td>
                  <td v-if="Select=='Quarterly'" :style="item.data[3] && item.data[3].SI_JikuAvg  ? color(getAverage(item.data[3].SI_JikuAvg,item.data[2].SI_JikuAvg)):''" >
                    <v-icon small :style="item.data[3] && item.data[3].SI_JikuAvg  ? color(getAverage(item.data[3].SI_JikuAvg,item.data[2].SI_JikuAvg)):''">
                    {{ item.data[3] && item.data[3].SI_JikuAvg  ? icon(getAverage(item.data[3].SI_JikuAvg,item.data[2].SI_JikuAvg)):'' }}</v-icon>
                    {{ item.data[3] && item.data[3].SI_JikuAvg  ? getAverage(item.data[3].SI_JikuAvg,item.data[2].SI_JikuAvg) +'%':'' }}</td>
                  <td v-if="Select=='Quarterly'">{{ item.data[3] && item.data[3].SI_JikuAvg  ? item.data[3].SI_JikuAvg:'' }}</td>
                </tr>
                <tr v-if="row.row15 && row.row15 == true">
                  <td  class="bg" v-if="selected[0].title == 'ALVS_依頼回数平均'" :rowspan="selected.length ">{{ item.EmployeeCode }}</td>
                  <td  class="bg" v-if="selected[0].title == 'ALVS_依頼回数平均'" :rowspan="selected.length   ">{{  item.EmployeeName }}</td>
                  <td class="bg" >{{ 'ALVS_依頼回数平均' }}</td> 
                  <td>{{ '-' }}</td> 
                  <td>{{ item.data[0] && item.data[0].ALVSAvg ? item.data[0].ALVSAvg : ''}}</td>
                  <td :style="item.data[1] && item.data[1].ALVSAvg  ? color(getAverage(item.data[1].ALVSAvg,item.data[0].ALVSAvg)):''">
                    <v-icon small :style="item.data[1] && item.data[1].ALVSAvg  ? color(getAverage(item.data[1].ALVSAvg,item.data[0].ALVSAvg)):''">
                    {{ item.data[1] && item.data[1].ALVSAvg  ? icon(getAverage(item.data[1].ALVSAvg,item.data[0].ALVSAvg)) :''}}</v-icon>
                    {{item.data[1] && item.data[1].ALVSAvg  ?  getAverage(item.data[1].ALVSAvg,item.data[0].ALVSAvg) +'%' :''}}
                  </td>
                  <td>{{item.data[1] &&  item.data[1].ALVSAvg  ? item.data[1].ALVSAvg:''  }}</td>
                  <td v-if="Select=='Quarterly'" :style=" item.data[2] && item.data[2].ALVSAvg ? color(getAverage(item.data[2].ALVSAvg,item.data[1].ALVSAvg)):''" >
                    <v-icon small :style=" item.data[2] && item.data[2].ALVSAvg ? color(getAverage(item.data[2].ALVSAvg,item.data[1].ALVSAvg)):''">
                    {{  item.data[2] && item.data[2].ALVSAvg ? icon(getAverage(item.data[2].ALVSAvg,item.data[1].ALVSAvg)):'' }}</v-icon>
                    {{  item.data[2] && item.data[2].ALVSAvg ? getAverage(item.data[2].ALVSAvg,item.data[1].ALVSAvg) +'%' :''}}</td>
                  <td v-if="Select=='Quarterly'">{{  item.data[2] && item.data[2].ALVSAvg ? item.data[2].ALVSAvg :''}}</td>
                  <td v-if="Select=='Quarterly'" :style="item.data[3] && item.data[3].ALVSAvg ? color(getAverage(item.data[3].ALVSAvg,item.data[2].ALVSAvg)):''" >
                    <v-icon small :style="item.data[3] && item.data[3].ALVSAvg ? color(getAverage(item.data[3].ALVSAvg,item.data[2].ALVSAvg)):''">
                    {{ item.data[3] && item.data[3].ALVSAvg ? icon(getAverage(item.data[3].ALVSAvg,item.data[2].ALVSAvg)) :''}}</v-icon>
                    {{ item.data[3] && item.data[3].ALVSAvg ? getAverage(item.data[3].ALVSAvg,item.data[2].ALVSAvg) +'%' :''}}</td>
                  <td v-if="Select=='Quarterly'">{{ item.data[3] && item.data[3].ALVSAvg ? item.data[3].ALVSAvg:'' }}</td>
                </tr>
                <tr v-if="row.row16 && row.row16 == true">
                  <td  class="bg" v-if="selected[0].title == '土地決定～加工依頼_日数平均'" :rowspan="selected.length ">{{ item.EmployeeCode }}</td>
                  <td  class="bg" v-if="selected[0].title == '土地決定～加工依頼_日数平均'" :rowspan="selected.length   ">{{  item.EmployeeName }}</td>
                  <td class="bg" >{{ '土地決定～加工依頼_日数平均' }}</td> 
                  <td>{{ '-' }}</td> 
                  <td>{{ item.data[0] && item.data[0].DecidedLandDaysAvg ? item.data[0].DecidedLandDaysAvg :''}}</td>
                  <td :style="item.data[1] && item.data[1].DecidedLandDaysAvg  ? color(getAverage(item.data[1].DecidedLandDaysAvg,item.data[0].DecidedLandDaysAvg)):''">
                    <v-icon small :style="item.data[1] && item.data[1].DecidedLandDaysAvg  ? color(getAverage(item.data[1].DecidedLandDaysAvg,item.data[0].DecidedLandDaysAvg)):''">
                    {{ item.data[1] && item.data[1].DecidedLandDaysAvg  ? icon(getAverage(item.data[1].DecidedLandDaysAvg,item.data[0].DecidedLandDaysAvg)):'' }}</v-icon>
                    {{ item.data[1] && item.data[1].DecidedLandDaysAvg  ? getAverage(item.data[1].DecidedLandDaysAvg,item.data[0].DecidedLandDaysAvg) +'%':'' }}
                  </td>
                  <td>{{ item.data[1] && item.data[1].DecidedLandDaysAvg  ? item.data[1].DecidedLandDaysAvg :'' }}</td>
                  <td v-if="Select=='Quarterly'" :style="item.data[2] && item.data[2].DecidedLandDaysAvg ? color(getAverage(item.data[2].DecidedLandDaysAvg,item.data[1].DecidedLandDaysAvg)):''" >
                    <v-icon small :style="item.data[2] && item.data[2].DecidedLandDaysAvg ? color(getAverage(item.data[2].DecidedLandDaysAvg,item.data[1].DecidedLandDaysAvg)):''">
                    {{ item.data[2] && item.data[2].DecidedLandDaysAvg ? icon(getAverage(item.data[2].DecidedLandDaysAvg,item.data[1].DecidedLandDaysAvg)):'' }}</v-icon>
                    {{ item.data[2] && item.data[2].DecidedLandDaysAvg ? getAverage(item.data[2].DecidedLandDaysAvg,item.data[1].DecidedLandDaysAvg) +'%' :''}}</td>
                  <td v-if="Select=='Quarterly'">{{ item.data[2] && item.data[2].DecidedLandDaysAvg ? item.data[2].DecidedLandDaysAvg :''}}</td>
                  <td v-if="Select=='Quarterly'" :style="item.data[3] && item.data[3].DecidedLandDaysAvg ? color(getAverage(item.data[3].DecidedLandDaysAvg,item.data[2].DecidedLandDaysAvg)):''" >
                    <v-icon small :style="item.data[3] && item.data[3].DecidedLandDaysAvg ? color(getAverage(item.data[3].DecidedLandDaysAvg,item.data[2].DecidedLandDaysAvg)):''">
                    {{ item.data[3] && item.data[3].DecidedLandDaysAvg ? icon(getAverage(item.data[3].DecidedLandDaysAvg,item.data[2].DecidedLandDaysAvg)):'' }}</v-icon>
                    {{item.data[3] && item.data[3].DecidedLandDaysAvg ?  getAverage(item.data[3].DecidedLandDaysAvg,item.data[2].DecidedLandDaysAvg) +'%' :''}}</td>
                  <td v-if="Select=='Quarterly'">{{ item.data[3] && item.data[3].DecidedLandDaysAvg ? item.data[3].DecidedLandDaysAvg:'' }}</td>
                </tr>
                <tr v-if="row.row17 && row.row17 == true">
                  <td  class="bg" v-if="selected[0].title == 'STOP～加工依頼_日数平均'" :rowspan="selected.length ">{{ item.EmployeeCode }}</td>
                  <td  class="bg" v-if="selected[0].title == 'STOP～加工依頼_日数平均'" :rowspan="selected.length   ">{{  item.EmployeeName }}</td>
                  <td class="bg" >{{ 'STOP～加工依頼_日数平均' }}</td> 
                  <td>{{ '-' }}</td> 
                  <td>{{ item.data[0] && item.data[0].StopCheckAvg ? item.data[0].StopCheckAvg :''}}</td>
                  <td :style="item.data[1] && item.data[1].StopCheckAvg ? color(getAverage(item.data[1].StopCheckAvg,item.data[0].StopCheckAvg)):''">
                    <v-icon small :style="item.data[1] && item.data[1].StopCheckAvg ? color(getAverage(item.data[1].StopCheckAvg,item.data[0].StopCheckAvg)):''">
                    {{ item.data[1] && item.data[1].StopCheckAvg ? icon(getAverage(item.data[1].StopCheckAvg,item.data[0].StopCheckAvg)):'' }}</v-icon>
                    {{ item.data[1] && item.data[1].StopCheckAvg ? getAverage(item.data[1].StopCheckAvg,item.data[0].StopCheckAvg) +'%':'' }}
                  </td>
                  <td>{{ item.data[1] && item.data[1].StopCheckAvg ? item.data[1].StopCheckAvg :'' }}</td>
                  <td v-if="Select=='Quarterly'" :style="item.data[2] && item.data[2].StopCheckAvg ?color(getAverage(item.data[2].StopCheckAvg,item.data[1].StopCheckAvg)):''" >
                    <v-icon small :style="item.data[2] && item.data[2].StopCheckAvg ?color(getAverage(item.data[2].StopCheckAvg,item.data[1].StopCheckAvg)):''">
                    {{ item.data[2] && item.data[2].StopCheckAvg ?icon(getAverage(item.data[2].StopCheckAvg,item.data[1].StopCheckAvg)):'' }}</v-icon>
                    {{ item.data[2] && item.data[2].StopCheckAvg ?getAverage(item.data[2].StopCheckAvg,item.data[1].StopCheckAvg) +'%':'' }}</td>
                  <td v-if="Select=='Quarterly'">{{item.data[2] && item.data[2].StopCheckAvg ? item.data[2].StopCheckAvg :''}}</td>
                  <td v-if="Select=='Quarterly'" :style="item.data[3] && item.data[3].StopCheckAvg  ? color(getAverage(item.data[3].StopCheckAvg,item.data[2].StopCheckAvg)):''" >
                    <v-icon small :style="item.data[3] && item.data[3].StopCheckAvg  ? color(getAverage(item.data[3].StopCheckAvg,item.data[2].StopCheckAvg)):''">
                    {{ item.data[3] && item.data[3].StopCheckAvg  ? icon(getAverage(item.data[3].StopCheckAvg,item.data[2].StopCheckAvg)):'' }}</v-icon>
                    {{ item.data[3] && item.data[3].StopCheckAvg  ? getAverage(item.data[3].StopCheckAvg,item.data[2].StopCheckAvg) +'%' :''}}</td>
                  <td v-if="Select=='Quarterly'">{{ item.data[3] && item.data[3].StopCheckAvg  ? item.data[3].StopCheckAvg :''}}</td>
                </tr>
                <tr v-if="row.row18 && row.row18 == true">
                  <td  class="bg" v-if="selected[0].title == '着手準備~加工依頼_日数平均'" :rowspan="selected.length ">{{ item.EmployeeCode }}</td>
                  <td  class="bg" v-if="selected[0].title == '着手準備~加工依頼_日数平均'" :rowspan="selected.length   ">{{  item.EmployeeName }}</td>
                  <td class="bg" >{{ '着手準備~加工依頼_日数平均' }}</td> 
                  <td>{{ '-' }}</td> 
                  <td>{{ item.data[0] && item.data[0].FinalPlanAvg ? item.data[0].FinalPlanAvg :'' }}</td>
                  <td :style="item.data[1] && item.data[1].FinalPlanAvg ? color(getAverage(item.data[1].FinalPlanAvg,item.data[0].FinalPlanAvg)):''">
                    <v-icon small :style="item.data[1] && item.data[1].FinalPlanAvg ? color(getAverage(item.data[1].FinalPlanAvg,item.data[0].FinalPlanAvg)):''">
                    {{ item.data[1] && item.data[1].FinalPlanAvg ? icon(getAverage(item.data[1].FinalPlanAvg,item.data[0].FinalPlanAvg)):''}}</v-icon>
                    {{ item.data[1] && item.data[1].FinalPlanAvg ? getAverage(item.data[1].FinalPlanAvg,item.data[0].FinalPlanAvg) +'%':'' }}
                  </td>
                  <td>{{ item.data[1] && item.data[1].FinalPlanAvg ? item.data[1].FinalPlanAvg :'' }}</td>
                  <td v-if="Select=='Quarterly'" :style="item.data[2] && item.data[2].FinalPlanAvg  ? color(getAverage(item.data[2].FinalPlanAvg,item.data[1].FinalPlanAvg)):''" >
                    <v-icon small :style="item.data[2] && item.data[2].FinalPlanAvg  ? color(getAverage(item.data[2].FinalPlanAvg,item.data[1].FinalPlanAvg)):''">
                    {{ item.data[2] && item.data[2].FinalPlanAvg  ? icon(getAverage(item.data[2].FinalPlanAvg,item.data[1].FinalPlanAvg)):'' }}</v-icon>
                    {{ item.data[2] && item.data[2].FinalPlanAvg  ? getAverage(item.data[2].FinalPlanAvg,item.data[1].FinalPlanAvg) +'%':'' }}</td>
                  <td v-if="Select=='Quarterly'">{{ item.data[2] && item.data[2].FinalPlanAvg  ? item.data[2].FinalPlanAvg :''}}</td>
                  <td v-if="Select=='Quarterly'"  :style="item.data[3] && item.data[3].FinalPlanAvg ?color(getAverage(item.data[3].FinalPlanAvg,item.data[2].FinalPlanAvg)):''" >
                    <v-icon small :style="item.data[3] && item.data[3].FinalPlanAvg ?color(getAverage(item.data[3].FinalPlanAvg,item.data[2].FinalPlanAvg)):''">
                    {{ item.data[3] && item.data[3].FinalPlanAvg ?icon(getAverage(item.data[3].FinalPlanAvg,item.data[2].FinalPlanAvg)):''}}</v-icon>
                    {{ item.data[3] && item.data[3].FinalPlanAvg ?getAverage(item.data[3].FinalPlanAvg,item.data[2].FinalPlanAvg) +'%' :''}}</td>
                  <td v-if="Select=='Quarterly'" >{{item.data[3] && item.data[3].FinalPlanAvg ? item.data[3].FinalPlanAvg:'' }}</td>
                </tr>
                <tr v-if="row.row19 && row.row19 == true">
                  <td  class="bg" v-if="selected[0].title == '加工依頼～上棟_日数平均'" :rowspan="selected.length ">{{ item.EmployeeCode }}</td>
                  <td  class="bg" v-if="selected[0].title == '加工依頼～上棟_日数平均'" :rowspan="selected.length   ">{{  item.EmployeeName }}</td>
                  <td class="bg" >{{ '加工依頼～上棟_日数平均' }}</td> 
                  <td>{{ '-' }}</td>
                  <td>{{ item.data[0] && item.data[0].JoutouAvg?item.data[0].JoutouAvg:'' }}</td>
                  <td :style="item.data[1] && item.data[1].JoutouAvg ?color(getAverage(item.data[1].JoutouAvg,item.data[0].JoutouAvg)):''">
                    <v-icon small :style="item.data[1] && item.data[1].JoutouAvg ?color(getAverage(item.data[1].JoutouAvg,item.data[0].JoutouAvg)):''">
                    {{item.data[1] && item.data[1].JoutouAvg ? icon(getAverage(item.data[1].JoutouAvg,item.data[0].JoutouAvg)) :''}}</v-icon>
                    {{ item.data[1] && item.data[1].JoutouAvg ?getAverage(item.data[1].JoutouAvg,item.data[0].JoutouAvg) +'%' :''}}
                  </td>
                  <td>{{item.data[1] && item.data[1].JoutouAvg ? item.data[1].JoutouAvg :'' }}</td>
                  <td v-if="Select=='Quarterly'" :style="item.data[2] && item.data[2].JoutouAvg ? color(getAverage(item.data[2].JoutouAvg,item.data[1].JoutouAvg)):''" >
                    <v-icon small :style="item.data[2] && item.data[2].JoutouAvg ? color(getAverage(item.data[2].JoutouAvg,item.data[1].JoutouAvg)):''">
                    {{ item.data[2] && item.data[2].JoutouAvg ? icon(getAverage(item.data[2].JoutouAvg,item.data[1].JoutouAvg)) :''}}</v-icon>
                    {{ item.data[2] && item.data[2].JoutouAvg ? getAverage(item.data[2].JoutouAvg,item.data[1].JoutouAvg) +'%':'' }}</td>
                  <td v-if="Select=='Quarterly'">{{item.data[2] && item.data[2].JoutouAvg ? item.data[2].JoutouAvg:'' }}</td>
                  <td v-if="Select=='Quarterly'" :style="item.data[3] && item.data[3].JoutouAvg ?color(getAverage(item.data[3].JoutouAvg,item.data[2].JoutouAvg)):''" >
                    <v-icon small :style="item.data[3] && item.data[3].JoutouAvg ?color(getAverage(item.data[3].JoutouAvg,item.data[2].JoutouAvg)):''">
                    {{item.data[3] && item.data[3].JoutouAvg ? icon(getAverage(item.data[3].JoutouAvg,item.data[2].JoutouAvg)):'' }}</v-icon>
                    {{item.data[3] && item.data[3].JoutouAvg ? getAverage(item.data[3].JoutouAvg,item.data[2].JoutouAvg) +'%':'' }}</td>
                  <td v-if="Select=='Quarterly'"> {{item.data[3] && item.data[3].JoutouAvg ? item.data[3].JoutouAvg :''}}</td>
                </tr>
              </tbody>
              <tbody >
                <tr class="bg2" v-if="row.row1 && row.row1 == true">
                  <td style="border: 1px solid black" v-if="selected[0].title == '加工依頼数'" :rowspan="selected.length" colspan="2">{{ '営業所合計' }}</td>
                  <td>{{ '加工依頼数' }}</td> 
                  <td>{{ '-' }}</td>
                  <td>{{ Total[0] && Total[0].TotalKakouIrai ? Total[0].TotalKakouIrai : '' }}</td> 
                  <!-- <td :style="Total[1] && Total[1].TotalKakouIrai ? color(getAverage(Total[1].TotalKakouIrai,Total[0].TotalKakouIrai)):''"> -->
                  <td>
                    <!-- <v-icon small :style="Total[1] && Total[1].TotalKakouIrai ?color(getAverage(Total[1].TotalKakouIrai,Total[0].TotalKakouIrai)):''">
                    {{Total[1] && Total[1].TotalKakouIrai ? icon(getAverage(Total[1].TotalKakouIrai,Total[0].TotalKakouIrai)) :''}}</v-icon> -->
                    {{ Total[1] && Total[1].TotalKakouIrai ? getAverage(Total[1].TotalKakouIrai,Total[0].TotalKakouIrai)  +'%' : ''  }}</td> 
                  <td>{{ Total[1] && Total[1].TotalKakouIrai ? Total[1].TotalKakouIrai : '' }}</td> 
                  <!-- <td v-if="Select == 'Quarterly'" :style="Total[2] && Total[2].TotalKakouIrai ? color(getAverage(Total[2].TotalKakouIrai,Total[1].TotalKakouIrai)):''"> -->
                  <td v-if="Select == 'Quarterly'">
                    <!-- <v-icon small :style="Total[2] && Total[2].TotalKakouIrai ?color(getAverage(Total[2].TotalKakouIrai,Total[1].TotalKakouIrai)):''">
                    {{Total[2] && Total[2].TotalKakouIrai ? icon(getAverage(Total[2].TotalKakouIrai,Total[1].TotalKakouIrai)) :''}}</v-icon> -->
                    {{ Total[2] && Total[2].TotalKakouIrai ? getAverage(Total[2].TotalKakouIrai,Total[1].TotalKakouIrai)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'">{{ Total[2] && Total[2].TotalKakouIrai ? Total[2].TotalKakouIrai : '' }}</td> 
                  
                  <!-- <td v-if="Select == 'Quarterly'" :style="Total[3] && Total[3].TotalKakouIrai ? color(getAverage(Total[3].TotalKakouIrai,Total[2].TotalKakouIrai)):''"> -->
                  <td v-if="Select == 'Quarterly'">
                    <!-- <v-icon small :style="Total[3] && Total[3].TotalKakouIrai ?color(getAverage(Total[3].TotalKakouIrai,Total[2].TotalKakouIrai)):''">
                    {{Total[3] && Total[3].TotalKakouIrai ? icon(getAverage(Total[3].TotalKakouIrai,Total[2].TotalKakouIrai)) :''}}</v-icon> -->
                    {{ Total[3] && Total[3].TotalKakouIrai ? getAverage(Total[3].TotalKakouIrai,Total[2].TotalKakouIrai)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'">{{ Total[3] && Total[3].TotalKakouIrai ? Total[3].TotalKakouIrai : '' }}</td> 
                </tr>
                <tr class="bg2"  v-if="row.row2 && row.row2 == true">
                  <td v-if="selected[0].title == '追加変更数'" :rowspan="selected.length" colspan="2">{{ '営業所合計' }}</td>
                  <td>{{ '追加変更数' }}</td> 
                  <td>{{ '-' }}</td>
                  <td>{{ Total[0] && Total[0].TotalTH ? Total[0].TotalTH : '' }}</td> 
                  <td :style="Total[1] && Total[1].TotalTH ? color(getAverage(Total[1].TotalTH,Total[0].TotalTH)):''">
                    <v-icon small :style="Total[1] && Total[1].TotalTH ?color(getAverage(Total[1].TotalTH,Total[0].TotalTH)):''">
                    {{Total[1] && Total[1].TotalTH ? icon(getAverage(Total[1].TotalTH,Total[0].TotalTH)) :''}}</v-icon>
                    {{ Total[1] && Total[1].TotalTH ? getAverage(Total[1].TotalTH,Total[0].TotalTH)  +'%' : ''  }}</td> 
                  <td>{{ Total[1] && Total[1].TotalTH ? Total[1].TotalTH : '' }}</td> 
                  <td v-if="Select == 'Quarterly'"  :style="Total[2] && Total[2].TotalTH ? color(getAverage(Total[2].TotalTH,Total[1].TotalTH)):''">
                    <v-icon small :style="Total[2] && Total[2].TotalTH ?color(getAverage(Total[2].TotalTH,Total[1].TotalTH)):''">
                    {{Total[2] && Total[2].TotalTH ? icon(getAverage(Total[2].TotalTH,Total[1].TotalTH)) :''}}</v-icon>
                    {{ Total[2] && Total[2].TotalTH ? getAverage(Total[2].TotalTH,Total[1].TotalTH)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[2] && Total[2].TotalTH ? Total[2].TotalTH : '' }}</td> 
                  
                  <td v-if="Select == 'Quarterly'"  :style="Total[3] && Total[3].TotalTH ? color(getAverage(Total[3].TotalTH,Total[2].TotalTH)):''">
                    <v-icon small :style="Total[3] && Total[3].TotalTH ?color(getAverage(Total[3].TotalTH,Total[2].TotalTH)):''">
                    {{Total[3] && Total[3].TotalTH ? icon(getAverage(Total[3].TotalTH,Total[2].TotalTH)) :''}}</v-icon>
                    {{ Total[3] && Total[3].TotalTH ? getAverage(Total[3].TotalTH,Total[2].TotalTH)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[3] && Total[3].TotalTH ? Total[3].TotalTH : '' }}</td>
        
                </tr>
                <tr class="bg2"  v-if="row.row3 && row.row3 == true">
                  <td v-if="selected[0].title == '1件あたりの追加変更数'" :rowspan="selected.length" colspan="2">{{ '営業所合計' }}</td>
                  <td>{{ '1件あたりの追加変更数' }}</td> 
                  <td>{{ '-' }}</td>
                  <td>{{ Total[0] && Total[0].TotalTHAvg ? Total[0].TotalTHAvg : '' }}</td> 
                  <td :style="Total[1] && Total[1].TotalTHAvg ? color(getAverage(Total[1].TotalTHAvg,Total[0].TotalTHAvg)):''">
                    <v-icon small :style="Total[1] && Total[1].TotalTHAvg ?color(getAverage(Total[1].TotalTHAvg,Total[0].TotalTHAvg)):''">
                    {{Total[1] && Total[1].TotalTHAvg ? icon(getAverage(Total[1].TotalTHAvg,Total[0].TotalTHAvg)) :''}}</v-icon>
                    {{ Total[1] && Total[1].TotalTHAvg ? getAverage(Total[1].TotalTHAvg,Total[0].TotalTHAvg)  +'%' : ''  }}</td> 
                  <td>{{ Total[1] && Total[1].TotalTHAvg ? Total[1].TotalTHAvg : '' }}</td> 
                  <td v-if="Select == 'Quarterly'" :style="Total[2] && Total[2].TotalTHAvg ? color(getAverage(Total[2].TotalTHAvg,Total[1].TotalTHAvg)):''">
                    <v-icon small :style="Total[2] && Total[2].TotalTHAvg ?color(getAverage(Total[2].TotalTHAvg,Total[1].TotalTHAvg)):''">
                    {{Total[2] && Total[2].TotalTHAvg ? icon(getAverage(Total[2].TotalTHAvg,Total[1].TotalTHAvg)) :''}}</v-icon>
                    {{ Total[2] && Total[2].TotalTHAvg ? getAverage(Total[2].TotalTHAvg,Total[1].TotalTHAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'">{{ Total[2] && Total[2].TotalTHAvg ? Total[2].TotalTHAvg : '' }}</td> 
                  
                  <td v-if="Select == 'Quarterly'" :style="Total[3] && Total[3].TotalTHAvg ? color(getAverage(Total[3].TotalTHAvg,Total[2].TotalTHAvg)):''">
                    <v-icon small :style="Total[3] && Total[3].TotalTHAvg ?color(getAverage(Total[3].TotalTHAvg,Total[2].TotalTHAvg)):''">
                    {{Total[3] && Total[3].TotalTHAvg ? icon(getAverage(Total[3].TotalTHAvg,Total[2].TotalTHAvg)) :''}}</v-icon>
                    {{ Total[3] && Total[3].TotalTHAvg ? getAverage(Total[3].TotalTHAvg,Total[2].TotalTHAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'">{{ Total[3] && Total[3].TotalTHAvg ? Total[3].TotalTHAvg : '' }}</td>
                </tr>
                <tr class="bg2"  v-if="row.row4 && row.row4 == true">
                  <td v-if="selected[0].title == 'CAD_依頼回数平均'" :rowspan="selected.length" colspan="2">{{ '営業所合計' }}</td>
                  <td>{{ 'CAD_依頼回数平均' }}</td> 
                  <td>{{ '-' }}</td>
                  <td>{{ Total[0] && Total[0].CadTotalAvg ? Total[0].CadTotalAvg : '' }}</td> 
                  <td :style="Total[1] && Total[1].CadTotalAvg ? color(getAverage(Total[1].CadTotalAvg,Total[0].CadTotalAvg)):''">
                    <v-icon small :style="Total[1] && Total[1].CadTotalAvg ?color(getAverage(Total[1].CadTotalAvg,Total[0].CadTotalAvg)):''">
                    {{Total[1] && Total[1].CadTotalAvg ? icon(getAverage(Total[1].CadTotalAvg,Total[0].CadTotalAvg)) :''}}</v-icon>
                    {{ Total[1] && Total[1].CadTotalAvg ? getAverage(Total[1].CadTotalAvg,Total[0].CadTotalAvg)  +'%' : ''  }}</td> 
                  <td>{{ Total[1] && Total[1].CadTotalAvg ? Total[1].CadTotalAvg : '' }}</td> 
                  <td v-if="Select == 'Quarterly'"  :style="Total[2] && Total[2].CadTotalAvg ? color(getAverage(Total[2].CadTotalAvg,Total[1].CadTotalAvg)):''">
                    <v-icon small :style="Total[2] && Total[2].CadTotalAvg ?color(getAverage(Total[2].CadTotalAvg,Total[1].CadTotalAvg)):''">
                    {{Total[2] && Total[2].CadTotalAvg ? icon(getAverage(Total[2].CadTotalAvg,Total[1].CadTotalAvg)) :''}}</v-icon>
                    {{ Total[2] && Total[2].CadTotalAvg ? getAverage(Total[2].CadTotalAvg,Total[1].CadTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[2] && Total[2].CadTotalAvg ? Total[2].CadTotalAvg : '' }}</td> 
                  
                  <td v-if="Select == 'Quarterly'"  :style="Total[3] && Total[3].CadTotalAvg ? color(getAverage(Total[3].CadTotalAvg,Total[2].CadTotalAvg)):''">
                    <v-icon small :style="Total[3] && Total[3].CadTotalAvg ?color(getAverage(Total[3].CadTotalAvg,Total[2].CadTotalAvg)):''">
                    {{Total[3] && Total[3].CadTotalAvg ? icon(getAverage(Total[3].CadTotalAvg,Total[2].CadTotalAvg)) :''}}</v-icon>
                    {{ Total[3] && Total[3].CadTotalAvg ? getAverage(Total[3].CadTotalAvg,Total[2].CadTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[3] && Total[3].CadTotalAvg ? Total[3].CadTotalAvg : '' }}</td>
                </tr>
                <tr class="bg2"  v-if="row.row5 && row.row5 == true">
                  <td v-if="selected[0].title == '即日CAD_依頼数平均'" :rowspan="selected.length" colspan="2">{{ '営業所合計' }}</td>
                  <td>{{ '即日CAD_依頼数平均' }}</td> 
                  <td>{{ '-' }}</td>
                  <td>{{ Total[0] && Total[0].CadExpressTotalAvg ? Total[0].CadExpressTotalAvg : '' }}</td> 
                  <td :style="Total[1] && Total[1].CadExpressTotalAvg ? color(getAverage(Total[1].CadExpressTotalAvg,Total[0].CadExpressTotalAvg)):''">
                    <v-icon small :style="Total[1] && Total[1].CadExpressTotalAvg ?color(getAverage(Total[1].CadExpressTotalAvg,Total[0].CadExpressTotalAvg)):''">
                    {{Total[1] && Total[1].CadExpressTotalAvg ? icon(getAverage(Total[1].CadExpressTotalAvg,Total[0].CadExpressTotalAvg)) :''}}</v-icon>
                    {{ Total[1] && Total[1].CadExpressTotalAvg ? getAverage(Total[1].CadExpressTotalAvg,Total[0].CadExpressTotalAvg)  +'%' : ''  }}</td> 
                  <td>{{ Total[1] && Total[1].CadExpressTotalAvg ? Total[1].CadExpressTotalAvg : '' }}</td> 
                  <td v-if="Select == 'Quarterly'"  :style="Total[2] && Total[2].CadExpressTotalAvg ? color(getAverage(Total[2].CadExpressTotalAvg,Total[1].CadExpressTotalAvg)):''">
                    <v-icon small :style="Total[2] && Total[2].CadExpressTotalAvg ?color(getAverage(Total[2].CadExpressTotalAvg,Total[1].CadExpressTotalAvg)):''">
                    {{Total[2] && Total[2].CadExpressTotalAvg ? icon(getAverage(Total[2].CadExpressTotalAvg,Total[1].CadExpressTotalAvg)) :''}}</v-icon>
                    {{ Total[2] && Total[2].CadExpressTotalAvg ? getAverage(Total[2].CadExpressTotalAvg,Total[1].CadExpressTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[2] && Total[2].CadExpressTotalAvg ? Total[2].CadExpressTotalAvg : '' }}</td> 
                  
                  <td v-if="Select == 'Quarterly'"  :style="Total[3] && Total[3].CadExpressTotalAvg ? color(getAverage(Total[3].CadExpressTotalAvg,Total[2].CadExpressTotalAvg)):''">
                    <v-icon small :style="Total[3] && Total[3].CadExpressTotalAvg ?color(getAverage(Total[3].CadExpressTotalAvg,Total[2].CadExpressTotalAvg)):''">
                    {{Total[3] && Total[3].CadExpressTotalAvg ? icon(getAverage(Total[3].CadExpressTotalAvg,Total[2].CadExpressTotalAvg)) :''}}</v-icon>
                    {{ Total[3] && Total[3].CadExpressTotalAvg ? getAverage(Total[3].CadExpressTotalAvg,Total[2].CadExpressTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[3] && Total[3].CadExpressTotalAvg ? Total[3].CadExpressTotalAvg : '' }}</td>
                </tr>
                <tr class="bg2"  v-if="row.row7 && row.row7 == true">
                  <td v-if="selected[0].title == '提案電気図面依頼以降'" :rowspan="selected.length" colspan="2">{{ '営業所合計' }}</td>
                  <td>{{ '提案電気図面依頼以降' }}</td> 
                  <td>{{ '-' }}</td>
                  <td>{{ Total[0] && Total[0].CadPdrTotalAvg ? Total[0].CadPdrTotalAvg : '' }}</td> 
                  <td :style="Total[1] && Total[1].CadPdrTotalAvg ? color(getAverage(Total[1].CadPdrTotalAvg,Total[0].CadPdrTotalAvg)):''">
                    <v-icon small :style="Total[1] && Total[1].CadPdrTotalAvg ?color(getAverage(Total[1].CadPdrTotalAvg,Total[0].CadPdrTotalAvg)):''">
                    {{Total[1] && Total[1].CadPdrTotalAvg ? icon(getAverage(Total[1].CadPdrTotalAvg,Total[0].CadPdrTotalAvg)) :''}}</v-icon>
                    {{ Total[1] && Total[1].CadPdrTotalAvg ? getAverage(Total[1].CadPdrTotalAvg,Total[0].CadPdrTotalAvg)  +'%' : ''  }}</td> 
                  <td>{{ Total[1] && Total[1].CadPdrTotalAvg ? Total[1].CadPdrTotalAvg : '' }}</td> 
                  <td v-if="Select == 'Quarterly'"  :style="Total[2] && Total[2].CadPdrTotalAvg ? color(getAverage(Total[2].CadPdrTotalAvg,Total[1].CadPdrTotalAvg)):''">
                    <v-icon small :style="Total[2] && Total[2].CadPdrTotalAvg ?color(getAverage(Total[2].CadPdrTotalAvg,Total[1].CadPdrTotalAvg)):''">
                    {{Total[2] && Total[2].CadPdrTotalAvg ? icon(getAverage(Total[2].CadPdrTotalAvg,Total[1].CadPdrTotalAvg)) :''}}</v-icon>
                    {{ Total[2] && Total[2].CadPdrTotalAvg ? getAverage(Total[2].CadPdrTotalAvg,Total[1].CadPdrTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[2] && Total[2].CadPdrTotalAvg ? Total[2].CadPdrTotalAvg : '' }}</td> 
                  
                  <td v-if="Select == 'Quarterly'"  :style="Total[3] && Total[3].CadPdrTotalAvg ? color(getAverage(Total[3].CadPdrTotalAvg,Total[2].CadPdrTotalAvg)):''">
                    <v-icon small :style="Total[3] && Total[3].CadPdrTotalAvg ?color(getAverage(Total[3].CadPdrTotalAvg,Total[2].CadPdrTotalAvg)):''">
                    {{Total[3] && Total[3].CadPdrTotalAvg ? icon(getAverage(Total[3].CadPdrTotalAvg,Total[2].CadPdrTotalAvg)) :''}}</v-icon>
                    {{ Total[3] && Total[3].CadPdrTotalAvg ? getAverage(Total[3].CadPdrTotalAvg,Total[2].CadPdrTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[3] && Total[3].CadPdrTotalAvg ? Total[3].CadPdrTotalAvg : '' }}</td>
                </tr>
                <tr class="bg2"  v-if="row.row8 && row.row8 == true">
                  <td v-if="selected[0].title == '着手図面依頼以降'" :rowspan="selected.length" colspan="2">{{ '営業所合計' }}</td>
                  <td>{{ '着手図面依頼以降' }}</td> 
                  <td>{{ '-' }}</td>
                  <td>{{ Total[0] && Total[0].CadFinalTotalAvg ? Total[0].CadFinalTotalAvg : '' }}</td> 
                  <td :style="Total[1] && Total[1].CadFinalTotalAvg ? color(getAverage(Total[1].CadFinalTotalAvg,Total[0].CadFinalTotalAvg)):''">
                    <v-icon small :style="Total[1] && Total[1].CadFinalTotalAvg ?color(getAverage(Total[1].CadFinalTotalAvg,Total[0].CadFinalTotalAvg)):''">
                    {{Total[1] && Total[1].CadFinalTotalAvg ? icon(getAverage(Total[1].CadFinalTotalAvg,Total[0].CadFinalTotalAvg)) :''}}</v-icon>
                    {{ Total[1] && Total[1].CadFinalTotalAvg ? getAverage(Total[1].CadFinalTotalAvg,Total[0].CadFinalTotalAvg)  +'%' : ''  }}</td> 
                  <td>{{ Total[1] && Total[1].CadFinalTotalAvg ? Total[1].CadFinalTotalAvg : '' }}</td> 
                  <td v-if="Select == 'Quarterly'"  :style="Total[2] && Total[2].CadFinalTotalAvg ? color(getAverage(Total[2].CadFinalTotalAvg,Total[1].CadFinalTotalAvg)):''">
                    <v-icon small :style="Total[2] && Total[2].CadFinalTotalAvg ?color(getAverage(Total[2].CadFinalTotalAvg,Total[1].CadFinalTotalAvg)):''">
                    {{Total[2] && Total[2].CadFinalTotalAvg ? icon(getAverage(Total[2].CadFinalTotalAvg,Total[1].CadFinalTotalAvg)) :''}}</v-icon>
                    {{ Total[2] && Total[2].CadFinalTotalAvg ? getAverage(Total[2].CadFinalTotalAvg,Total[1].CadFinalTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[2] && Total[2].CadFinalTotalAvg ? Total[2].CadFinalTotalAvg : '' }}</td> 
                  
                  <td v-if="Select == 'Quarterly'"  :style="Total[3] && Total[3].CadFinalTotalAvg ? color(getAverage(Total[3].CadFinalTotalAvg,Total[2].CadFinalTotalAvg)):''">
                    <v-icon small :style="Total[3] && Total[3].CadFinalTotalAvg ?color(getAverage(Total[3].CadFinalTotalAvg,Total[2].CadFinalTotalAvg)):''">
                    {{Total[3] && Total[3].CadFinalTotalAvg ? icon(getAverage(Total[3].CadFinalTotalAvg,Total[2].CadFinalTotalAvg)) :''}}</v-icon>
                    {{ Total[3] && Total[3].CadFinalTotalAvg ? getAverage(Total[3].CadFinalTotalAvg,Total[2].CadFinalTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[3] && Total[3].CadFinalTotalAvg ? Total[3].CadFinalTotalAvg : '' }}</td>
                </tr>



                <tr class="bg2"  v-if="row.row9 && row.row9 == true">
                  <td v-if="selected[0].title == '電気図面_依頼回数平均'" :rowspan="selected.length" colspan="2">{{ '営業所合計' }}</td>
                  <td>{{ '電気図面_依頼回数平均' }}</td> 
                  <td>{{ '-' }}</td>
                  <td>{{ Total[0] && Total[0].DenkiTotalAvg ? Total[0].DenkiTotalAvg : '' }}</td> 
                  <td :style="Total[1] && Total[1].DenkiTotalAvg ? color(getAverage(Total[1].DenkiTotalAvg,Total[0].DenkiTotalAvg)):''">
                    <v-icon small :style="Total[1] && Total[1].DenkiTotalAvg ?color(getAverage(Total[1].DenkiTotalAvg,Total[0].DenkiTotalAvg)):''">
                    {{Total[1] && Total[1].DenkiTotalAvg ? icon(getAverage(Total[1].DenkiTotalAvg,Total[0].DenkiTotalAvg)) :''}}</v-icon>
                    {{ Total[1] && Total[1].DenkiTotalAvg ? getAverage(Total[1].DenkiTotalAvg,Total[0].DenkiTotalAvg)  +'%' : ''  }}</td> 
                  <td>{{ Total[1] && Total[1].DenkiTotalAvg ? Total[1].DenkiTotalAvg : '' }}</td> 
                  <td v-if="Select == 'Quarterly'"  :style="Total[2] && Total[2].DenkiTotalAvg ? color(getAverage(Total[2].DenkiTotalAvg,Total[1].DenkiTotalAvg)):''">
                    <v-icon small :style="Total[2] && Total[2].DenkiTotalAvg ?color(getAverage(Total[2].DenkiTotalAvg,Total[1].DenkiTotalAvg)):''">
                    {{Total[2] && Total[2].DenkiTotalAvg ? icon(getAverage(Total[2].DenkiTotalAvg,Total[1].DenkiTotalAvg)) :''}}</v-icon>
                    {{ Total[2] && Total[2].DenkiTotalAvg ? getAverage(Total[2].DenkiTotalAvg,Total[1].DenkiTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[2] && Total[2].DenkiTotalAvg ? Total[2].DenkiTotalAvg : '' }}</td> 
                  
                  <td v-if="Select == 'Quarterly'"  :style="Total[3] && Total[3].DenkiTotalAvg ? color(getAverage(Total[3].DenkiTotalAvg,Total[2].DenkiTotalAvg)):''">
                    <v-icon small :style="Total[3] && Total[3].DenkiTotalAvg ?color(getAverage(Total[3].DenkiTotalAvg,Total[2].DenkiTotalAvg)):''">
                    {{Total[3] && Total[3].DenkiTotalAvg ? icon(getAverage(Total[3].DenkiTotalAvg,Total[2].DenkiTotalAvg)) :''}}</v-icon>
                    {{ Total[3] && Total[3].DenkiTotalAvg ? getAverage(Total[3].DenkiTotalAvg,Total[2].DenkiTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[3] && Total[3].DenkiTotalAvg ? Total[3].DenkiTotalAvg : '' }}</td>
                </tr>
                <tr class="bg2"  v-if="row.row10 && row.row10 == true">
                  <td v-if="selected[0].title == '即日電気_依頼数平均'" :rowspan="selected.length" colspan="2">{{ '営業所合計' }}</td>
                  <td>{{ '即日電気_依頼数平均' }}</td> 
                  <td>{{ '-' }}</td>
                  <td>{{ Total[0] && Total[0].DenkiExpressTotalAvg ? Total[0].DenkiExpressTotalAvg : '' }}</td> 
                  <td :style="Total[1] && Total[1].DenkiExpressTotalAvg ? color(getAverage(Total[1].DenkiExpressTotalAvg,Total[0].DenkiExpressTotalAvg)):''">
                    <v-icon small :style="Total[1] && Total[1].DenkiExpressTotalAvg ?color(getAverage(Total[1].DenkiExpressTotalAvg,Total[0].DenkiExpressTotalAvg)):''">
                    {{Total[1] && Total[1].DenkiExpressTotalAvg ? icon(getAverage(Total[1].DenkiExpressTotalAvg,Total[0].DenkiExpressTotalAvg)) :''}}</v-icon>
                    {{ Total[1] && Total[1].DenkiExpressTotalAvg ? getAverage(Total[1].DenkiExpressTotalAvg,Total[0].DenkiExpressTotalAvg)  +'%' : ''  }}</td> 
                  <td>{{ Total[1] && Total[1].DenkiExpressTotalAvg ? Total[1].DenkiExpressTotalAvg : '' }}</td> 
                  <td v-if="Select == 'Quarterly'"  :style="Total[2] && Total[2].DenkiExpressTotalAvg ? color(getAverage(Total[2].DenkiExpressTotalAvg,Total[1].DenkiExpressTotalAvg)):''">
                    <v-icon small :style="Total[2] && Total[2].DenkiExpressTotalAvg ?color(getAverage(Total[2].DenkiExpressTotalAvg,Total[1].DenkiExpressTotalAvg)):''">
                    {{Total[2] && Total[2].DenkiExpressTotalAvg ? icon(getAverage(Total[2].DenkiExpressTotalAvg,Total[1].DenkiExpressTotalAvg)) :''}}</v-icon>
                    {{ Total[2] && Total[2].DenkiExpressTotalAvg ? getAverage(Total[2].DenkiExpressTotalAvg,Total[1].DenkiExpressTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[2] && Total[2].DenkiExpressTotalAvg ? Total[2].DenkiExpressTotalAvg : '' }}</td> 
                  
                  <td v-if="Select == 'Quarterly'"  :style="Total[3] && Total[3].DenkiExpressTotalAvg ? color(getAverage(Total[3].DenkiExpressTotalAvg,Total[2].DenkiExpressTotalAvg)):''">
                    <v-icon small :style="Total[3] && Total[3].DenkiExpressTotalAvg ?color(getAverage(Total[3].DenkiExpressTotalAvg,Total[2].DenkiExpressTotalAvg)):''">
                    {{Total[3] && Total[3].DenkiExpressTotalAvg ? icon(getAverage(Total[3].DenkiExpressTotalAvg,Total[2].DenkiExpressTotalAvg)) :''}}</v-icon>
                    {{ Total[3] && Total[3].DenkiExpressTotalAvg ? getAverage(Total[3].DenkiExpressTotalAvg,Total[2].DenkiExpressTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[3] && Total[3].DenkiExpressTotalAvg ? Total[3].DenkiExpressTotalAvg : '' }}</td>
                </tr>
                <tr class="bg2"  v-if="row.row11 && row.row11 == true">
                  <td v-if="selected[0].title == 'カラーパース_依頼回数平均'" :rowspan="selected.length" colspan="2">{{ '営業所合計' }}</td>
                  <td>{{ 'カラーパース_依頼回数平均' }}</td> 
                  <td>{{ '-' }}</td>
                  <td>{{ Total[0] && Total[0].ExternalTotalAvg ? Total[0].ExternalTotalAvg : '' }}</td> 
                  <td :style="Total[1] && Total[1].ExternalTotalAvg ? color(getAverage(Total[1].ExternalTotalAvg,Total[0].ExternalTotalAvg)):''">
                    <v-icon small :style="Total[1] && Total[1].ExternalTotalAvg ?color(getAverage(Total[1].ExternalTotalAvg,Total[0].ExternalTotalAvg)):''">
                    {{Total[1] && Total[1].ExternalTotalAvg ? icon(getAverage(Total[1].ExternalTotalAvg,Total[0].ExternalTotalAvg)) :''}}</v-icon>
                    {{ Total[1] && Total[1].ExternalTotalAvg ? getAverage(Total[1].ExternalTotalAvg,Total[0].ExternalTotalAvg)  +'%' : ''  }}</td> 
                  <td>{{ Total[1] && Total[1].ExternalTotalAvg ? Total[1].ExternalTotalAvg : '' }}</td> 
                  <td v-if="Select == 'Quarterly'"  :style="Total[2] && Total[2].ExternalTotalAvg ? color(getAverage(Total[2].ExternalTotalAvg,Total[1].ExternalTotalAvg)):''">
                    <v-icon small :style="Total[2] && Total[2].ExternalTotalAvg ?color(getAverage(Total[2].ExternalTotalAvg,Total[1].ExternalTotalAvg)):''">
                    {{Total[2] && Total[2].ExternalTotalAvg ? icon(getAverage(Total[2].ExternalTotalAvg,Total[1].ExternalTotalAvg)) :''}}</v-icon>
                    {{ Total[2] && Total[2].ExternalTotalAvg ? getAverage(Total[2].ExternalTotalAvg,Total[1].ExternalTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[2] && Total[2].ExternalTotalAvg ? Total[2].ExternalTotalAvg : '' }}</td> 
                  
                  <td v-if="Select == 'Quarterly'"  :style="Total[3] && Total[3].ExternalTotalAvg ? color(getAverage(Total[3].ExternalTotalAvg,Total[2].ExternalTotalAvg)):''">
                    <v-icon small :style="Total[3] && Total[3].ExternalTotalAvg ?color(getAverage(Total[3].ExternalTotalAvg,Total[2].ExternalTotalAvg)):''">
                    {{Total[3] && Total[3].ExternalTotalAvg ? icon(getAverage(Total[3].ExternalTotalAvg,Total[2].ExternalTotalAvg)) :''}}</v-icon>
                    {{ Total[3] && Total[3].ExternalTotalAvg ? getAverage(Total[3].ExternalTotalAvg,Total[2].ExternalTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[3] && Total[3].ExternalTotalAvg ? Total[3].ExternalTotalAvg : '' }}</td>
                </tr>
                <tr class="bg2"  v-if="row.row12 && row.row12 == true">
                  <td v-if="selected[0].title == 'HP_依頼回数平均'" :rowspan="selected.length" colspan="2">{{ '営業所合計' }}</td>
                  <td>{{ 'HP_依頼回数平均' }}</td> 
                  <td>{{ '-' }}</td>
                  <td>{{ Total[0] && Total[0].HPTotalAvg ? Total[0].HPTotalAvg : '' }}</td> 
                  <td :style="Total[1] && Total[1].HPTotalAvg ? color(getAverage(Total[1].HPTotalAvg,Total[0].HPTotalAvg)):''">
                    <v-icon small :style="Total[1] && Total[1].HPTotalAvg ?color(getAverage(Total[1].HPTotalAvg,Total[0].HPTotalAvg)):''">
                    {{Total[1] && Total[1].HPTotalAvg ? icon(getAverage(Total[1].HPTotalAvg,Total[0].HPTotalAvg)) :''}}</v-icon>
                    {{ Total[1] && Total[1].HPTotalAvg ? getAverage(Total[1].HPTotalAvg,Total[0].HPTotalAvg)  +'%' : ''  }}</td> 
                  <td>{{ Total[1] && Total[1].HPTotalAvg ? Total[1].HPTotalAvg : '' }}</td> 
                  <td v-if="Select == 'Quarterly'"  :style="Total[2] && Total[2].HPTotalAvg ? color(getAverage(Total[2].HPTotalAvg,Total[1].HPTotalAvg)):''">
                    <v-icon small :style="Total[2] && Total[2].HPTotalAvg ?color(getAverage(Total[2].HPTotalAvg,Total[1].HPTotalAvg)):''">
                    {{Total[2] && Total[2].HPTotalAvg ? icon(getAverage(Total[2].HPTotalAvg,Total[1].HPTotalAvg)) :''}}</v-icon>
                    {{ Total[2] && Total[2].HPTotalAvg ? getAverage(Total[2].HPTotalAvg,Total[1].HPTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[2] && Total[2].HPTotalAvg ? Total[2].HPTotalAvg : '' }}</td> 
                  
                  <td v-if="Select == 'Quarterly'"  :style="Total[3] && Total[3].HPTotalAvg ? color(getAverage(Total[3].HPTotalAvg,Total[2].HPTotalAvg)):''">
                    <v-icon small :style="Total[3] && Total[3].HPTotalAvg ?color(getAverage(Total[3].HPTotalAvg,Total[2].HPTotalAvg)):''">
                    {{Total[3] && Total[3].HPTotalAvg ? icon(getAverage(Total[3].HPTotalAvg,Total[2].HPTotalAvg)) :''}}</v-icon>
                    {{ Total[3] && Total[3].HPTotalAvg ? getAverage(Total[3].HPTotalAvg,Total[2].HPTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[3] && Total[3].HPTotalAvg ? Total[3].HPTotalAvg : '' }}</td>
                </tr>
                <tr class="bg2"  v-if="row.row13 && row.row13 == true">
                  <td v-if="selected[0].title == '構造事前検討（枠）_依頼回数平均'" :rowspan="selected.length" colspan="2">{{ '営業所合計' }}</td>
                  <td>{{ '構造事前検討（枠）_依頼回数平均' }}</td> 
                  <td>{{ '-' }}</td>
                  <td>{{ Total[0] && Total[0].SI_WakuTotalAvg ? Total[0].SI_WakuTotalAvg : '' }}</td> 
                  <td :style="Total[1] && Total[1].SI_WakuTotalAvg ? color(getAverage(Total[1].SI_WakuTotalAvg,Total[0].SI_WakuTotalAvg)):''">
                    <v-icon small :style="Total[1] && Total[1].SI_WakuTotalAvg ?color(getAverage(Total[1].SI_WakuTotalAvg,Total[0].SI_WakuTotalAvg)):''">
                    {{Total[1] && Total[1].SI_WakuTotalAvg ? icon(getAverage(Total[1].SI_WakuTotalAvg,Total[0].SI_WakuTotalAvg)) :''}}</v-icon>
                    {{ Total[1] && Total[1].SI_WakuTotalAvg ? getAverage(Total[1].SI_WakuTotalAvg,Total[0].SI_WakuTotalAvg)  +'%' : ''  }}</td> 
                  <td>{{ Total[1] && Total[1].SI_WakuTotalAvg ? Total[1].SI_WakuTotalAvg : '' }}</td> 
                  <td v-if="Select == 'Quarterly'"  :style="Total[2] && Total[2].SI_WakuTotalAvg ? color(getAverage(Total[2].SI_WakuTotalAvg,Total[1].SI_WakuTotalAvg)):''">
                    <v-icon small :style="Total[2] && Total[2].SI_WakuTotalAvg ?color(getAverage(Total[2].SI_WakuTotalAvg,Total[1].SI_WakuTotalAvg)):''">
                    {{Total[2] && Total[2].SI_WakuTotalAvg ? icon(getAverage(Total[2].SI_WakuTotalAvg,Total[1].SI_WakuTotalAvg)) :''}}</v-icon>
                    {{ Total[2] && Total[2].SI_WakuTotalAvg ? getAverage(Total[2].SI_WakuTotalAvg,Total[1].SI_WakuTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[2] && Total[2].SI_WakuTotalAvg ? Total[2].SI_WakuTotalAvg : '' }}</td> 
                  
                  <td v-if="Select == 'Quarterly'"  :style="Total[3] && Total[3].SI_WakuTotalAvg ? color(getAverage(Total[3].SI_WakuTotalAvg,Total[2].SI_WakuTotalAvg)):''">
                    <v-icon small :style="Total[3] && Total[3].SI_WakuTotalAvg ?color(getAverage(Total[3].SI_WakuTotalAvg,Total[2].SI_WakuTotalAvg)):''">
                    {{Total[3] && Total[3].SI_WakuTotalAvg ? icon(getAverage(Total[3].SI_WakuTotalAvg,Total[2].SI_WakuTotalAvg)) :''}}</v-icon>
                    {{ Total[3] && Total[3].SI_WakuTotalAvg ? getAverage(Total[3].SI_WakuTotalAvg,Total[2].SI_WakuTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[3] && Total[3].SI_WakuTotalAvg ? Total[3].SI_WakuTotalAvg : '' }}</td>
                </tr>
                <tr class="bg2"  v-if="row.row14 && row.row14 == true">
                  <td v-if="selected[0].title == '構造事前検討（軸）_依頼回数平均'" :rowspan="selected.length" colspan="2">{{ '営業所合計' }}</td>
                  <td>{{ '構造事前検討（軸）_依頼回数平均' }}</td> 
                  <td>{{ '-' }}</td>
                  <td>{{ Total[0] && Total[0].SI_JikuTotalAvg ? Total[0].SI_JikuTotalAvg : '' }}</td> 
                  <td :style="Total[1] && Total[1].SI_JikuTotalAvg ? color(getAverage(Total[1].SI_JikuTotalAvg,Total[0].SI_JikuTotalAvg)):''">
                    <v-icon small :style="Total[1] && Total[1].SI_JikuTotalAvg ?color(getAverage(Total[1].SI_JikuTotalAvg,Total[0].SI_JikuTotalAvg)):''">
                    {{Total[1] && Total[1].SI_JikuTotalAvg ? icon(getAverage(Total[1].SI_JikuTotalAvg,Total[0].SI_JikuTotalAvg)) :''}}</v-icon>
                    {{ Total[1] && Total[1].SI_JikuTotalAvg ? getAverage(Total[1].SI_JikuTotalAvg,Total[0].SI_JikuTotalAvg)  +'%' : ''  }}</td> 
                  <td>{{ Total[1] && Total[1].SI_JikuTotalAvg ? Total[1].SI_JikuTotalAvg : '' }}</td> 
                  <td v-if="Select == 'Quarterly'"  :style="Total[2] && Total[2].SI_JikuTotalAvg ? color(getAverage(Total[2].SI_JikuTotalAvg,Total[1].SI_JikuTotalAvg)):''">
                    <v-icon small :style="Total[2] && Total[2].SI_JikuTotalAvg ?color(getAverage(Total[2].SI_JikuTotalAvg,Total[1].SI_JikuTotalAvg)):''">
                    {{Total[2] && Total[2].SI_JikuTotalAvg ? icon(getAverage(Total[2].SI_JikuTotalAvg,Total[1].SI_JikuTotalAvg)) :''}}</v-icon>
                    {{ Total[2] && Total[2].SI_JikuTotalAvg ? getAverage(Total[2].SI_JikuTotalAvg,Total[1].SI_JikuTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[2] && Total[2].SI_JikuTotalAvg ? Total[2].SI_JikuTotalAvg : '' }}</td> 
                  <td v-if="Select == 'Quarterly'"  :style="Total[3] && Total[3].SI_JikuTotalAvg ? color(getAverage(Total[3].SI_JikuTotalAvg,Total[2].SI_JikuTotalAvg)):''">
                    <v-icon small :style="Total[3] && Total[3].SI_JikuTotalAvg ?color(getAverage(Total[3].SI_JikuTotalAvg,Total[2].SI_JikuTotalAvg)):''">
                    {{Total[3] && Total[3].SI_JikuTotalAvg ? icon(getAverage(Total[3].SI_JikuTotalAvg,Total[2].SI_JikuTotalAvg)) :''}}</v-icon>
                    {{ Total[3] && Total[3].SI_JikuTotalAvg ? getAverage(Total[3].SI_JikuTotalAvg,Total[2].SI_JikuTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[3] && Total[3].SI_JikuTotalAvg ? Total[3].SI_JikuTotalAvg : '' }}</td>
                </tr>
                <tr class="bg2"  v-if="row.row15 && row.row15 == true">
                  <td v-if="selected[0].title == 'ALVS_依頼回数平均'" :rowspan="selected.length" colspan="2">{{ '営業所合計' }}</td>
                  <td>{{ 'ALVS_依頼回数平均' }}</td> 
                  <td>{{ '-' }}</td>
                  <td>{{ Total[0] && Total[0].ALVSTotalAvg ? Total[0].ALVSTotalAvg : '' }}</td> 
                  <td :style="Total[1] && Total[1].ALVSTotalAvg ? color(getAverage(Total[1].ALVSTotalAvg,Total[0].ALVSTotalAvg)):''">
                    <v-icon small :style="Total[1] && Total[1].ALVSTotalAvg ?color(getAverage(Total[1].ALVSTotalAvg,Total[0].ALVSTotalAvg)):''">
                    {{Total[1] && Total[1].ALVSTotalAvg ? icon(getAverage(Total[1].ALVSTotalAvg,Total[0].ALVSTotalAvg)) :''}}</v-icon>
                    {{ Total[1] && Total[1].ALVSTotalAvg ? getAverage(Total[1].ALVSTotalAvg,Total[0].ALVSTotalAvg)  +'%' : ''  }}</td> 
                  <td>{{ Total[1] && Total[1].ALVSTotalAvg ? Total[1].ALVSTotalAvg : '' }}</td> 
                  <td v-if="Select == 'Quarterly'"  :style="Total[2] && Total[2].ALVSTotalAvg ? color(getAverage(Total[2].ALVSTotalAvg,Total[1].ALVSTotalAvg)):''">
                    <v-icon small :style="Total[2] && Total[2].ALVSTotalAvg ?color(getAverage(Total[2].ALVSTotalAvg,Total[1].ALVSTotalAvg)):''">
                    {{Total[2] && Total[2].ALVSTotalAvg ? icon(getAverage(Total[2].ALVSTotalAvg,Total[1].ALVSTotalAvg)) :''}}</v-icon>
                    {{ Total[2] && Total[2].ALVSTotalAvg ? getAverage(Total[2].ALVSTotalAvg,Total[1].ALVSTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[2] && Total[2].ALVSTotalAvg ? Total[2].ALVSTotalAvg : '' }}</td> 
                  
                  <td v-if="Select == 'Quarterly'"  :style="Total[3] && Total[3].ALVSTotalAvg ? color(getAverage(Total[3].ALVSTotalAvg,Total[2].ALVSTotalAvg)):''">
                    <v-icon small :style="Total[3] && Total[3].ALVSTotalAvg ?color(getAverage(Total[3].ALVSTotalAvg,Total[2].ALVSTotalAvg)):''">
                    {{Total[3] && Total[3].ALVSTotalAvg ? icon(getAverage(Total[3].ALVSTotalAvg,Total[2].ALVSTotalAvg)) :''}}</v-icon>
                    {{ Total[3] && Total[3].ALVSTotalAvg ? getAverage(Total[3].ALVSTotalAvg,Total[2].ALVSTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[3] && Total[3].ALVSTotalAvg ? Total[3].ALVSTotalAvg : '' }}</td>
                </tr>
                <tr class="bg2"  v-if="row.row16 && row.row16 == true">
                  <td v-if="selected[0].title == '土地決定～加工依頼_日数平均'" :rowspan="selected.length" colspan="2">{{ '営業所合計' }}</td>
                  <td>{{ '土地決定～加工依頼_日数平均' }}</td> 
                  <td>{{ '-' }}</td>
                  <td>{{ Total[0] && Total[0].DecidedLandTotalAvg ? Total[0].DecidedLandTotalAvg : '' }}</td> 
                  <td :style="Total[1] && Total[1].DecidedLandTotalAvg ? color(getAverage(Total[1].DecidedLandTotalAvg,Total[0].DecidedLandTotalAvg)):''">
                    <v-icon small :style="Total[1] && Total[1].DecidedLandTotalAvg ?color(getAverage(Total[1].DecidedLandTotalAvg,Total[0].DecidedLandTotalAvg)):''">
                    {{Total[1] && Total[1].DecidedLandTotalAvg ? icon(getAverage(Total[1].DecidedLandTotalAvg,Total[0].DecidedLandTotalAvg)) :''}}</v-icon>
                    {{ Total[1] && Total[1].DecidedLandTotalAvg ? getAverage(Total[1].DecidedLandTotalAvg,Total[0].DecidedLandTotalAvg)  +'%' : ''  }}</td> 
                  <td>{{ Total[1] && Total[1].DecidedLandTotalAvg ? Total[1].DecidedLandTotalAvg : '' }}</td> 
                  <td v-if="Select == 'Quarterly'"  :style="Total[2] && Total[2].DecidedLandTotalAvg ? color(getAverage(Total[2].DecidedLandTotalAvg,Total[1].DecidedLandTotalAvg)):''">
                    <v-icon small :style="Total[2] && Total[2].DecidedLandTotalAvg ?color(getAverage(Total[2].DecidedLandTotalAvg,Total[1].DecidedLandTotalAvg)):''">
                    {{Total[2] && Total[2].DecidedLandTotalAvg ? icon(getAverage(Total[2].DecidedLandTotalAvg,Total[1].DecidedLandTotalAvg)) :''}}</v-icon>
                    {{ Total[2] && Total[2].DecidedLandTotalAvg ? getAverage(Total[2].DecidedLandTotalAvg,Total[1].DecidedLandTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[2] && Total[2].DecidedLandTotalAvg ? Total[2].DecidedLandTotalAvg : '' }}</td> 
                  
                  <td v-if="Select == 'Quarterly'"  :style="Total[3] && Total[3].DecidedLandTotalAvg ? color(getAverage(Total[3].DecidedLandTotalAvg,Total[2].DecidedLandTotalAvg)):''">
                    <v-icon small :style="Total[3] && Total[3].DecidedLandTotalAvg ?color(getAverage(Total[3].DecidedLandTotalAvg,Total[2].DecidedLandTotalAvg)):''">
                    {{Total[3] && Total[3].DecidedLandTotalAvg ? icon(getAverage(Total[3].DecidedLandTotalAvg,Total[2].DecidedLandTotalAvg)) :''}}</v-icon>
                    {{ Total[3] && Total[3].DecidedLandTotalAvg ? getAverage(Total[3].DecidedLandTotalAvg,Total[2].DecidedLandTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[3] && Total[3].DecidedLandTotalAvg ? Total[3].DecidedLandTotalAvg : '' }}</td>
                </tr>
                <tr class="bg2"  v-if="row.row17 && row.row17 == true">
                  <td v-if="selected[0].title == 'STOP～加工依頼_日数平均'" :rowspan="selected.length" colspan="2">{{ '営業所合計' }}</td>
                  <td>{{ 'STOP～加工依頼_日数平均' }}</td> 
                  <td>{{ '-' }}</td>
                  <td>{{ Total[0] && Total[0].StopCheckTotalAvg ? Total[0].StopCheckTotalAvg : '' }}</td> 
                  <td :style="Total[1] && Total[1].StopCheckTotalAvg ? color(getAverage(Total[1].StopCheckTotalAvg,Total[0].StopCheckTotalAvg)):''">
                    <v-icon small :style="Total[1] && Total[1].StopCheckTotalAvg ?color(getAverage(Total[1].StopCheckTotalAvg,Total[0].StopCheckTotalAvg)):''">
                    {{Total[1] && Total[1].StopCheckTotalAvg ? icon(getAverage(Total[1].StopCheckTotalAvg,Total[0].StopCheckTotalAvg)) :''}}</v-icon>
                    {{ Total[1] && Total[1].StopCheckTotalAvg ? getAverage(Total[1].StopCheckTotalAvg,Total[0].StopCheckTotalAvg)  +'%' : ''  }}</td> 
                  <td>{{ Total[1] && Total[1].StopCheckTotalAvg ? Total[1].StopCheckTotalAvg : '' }}</td> 
                  <td v-if="Select == 'Quarterly'"  :style="Total[2] && Total[2].StopCheckTotalAvg ? color(getAverage(Total[2].StopCheckTotalAvg,Total[1].StopCheckTotalAvg)):''">
                    <v-icon small :style="Total[2] && Total[2].StopCheckTotalAvg ?color(getAverage(Total[2].StopCheckTotalAvg,Total[1].StopCheckTotalAvg)):''">
                    {{Total[2] && Total[2].StopCheckTotalAvg ? icon(getAverage(Total[2].StopCheckTotalAvg,Total[1].StopCheckTotalAvg)) :''}}</v-icon>
                    {{ Total[2] && Total[2].StopCheckTotalAvg ? getAverage(Total[2].StopCheckTotalAvg,Total[1].StopCheckTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[2] && Total[2].StopCheckTotalAvg ? Total[2].StopCheckTotalAvg : '' }}</td> 
                  
                  <td v-if="Select == 'Quarterly'"  :style="Total[3] && Total[3].StopCheckTotalAvg ? color(getAverage(Total[3].StopCheckTotalAvg,Total[2].StopCheckTotalAvg)):''">
                    <v-icon small :style="Total[3] && Total[3].StopCheckTotalAvg ?color(getAverage(Total[3].StopCheckTotalAvg,Total[2].StopCheckTotalAvg)):''">
                    {{Total[3] && Total[3].StopCheckTotalAvg ? icon(getAverage(Total[3].StopCheckTotalAvg,Total[2].StopCheckTotalAvg)) :''}}</v-icon>
                    {{ Total[3] && Total[3].StopCheckTotalAvg ? getAverage(Total[3].StopCheckTotalAvg,Total[2].StopCheckTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[3] && Total[3].StopCheckTotalAvg ? Total[3].StopCheckTotalAvg : '' }}</td>
                </tr>
                <tr class="bg2"  v-if="row.row18 && row.row18 == true">
                  <td v-if="selected[0].title == '着手準備~加工依頼_日数平均'" :rowspan="selected.length" colspan="2">{{ '営業所合計' }}</td>
                  <td>{{ '着手準備~加工依頼_日数平均' }}</td> 
                  <td>{{ '-' }}</td>
                  <td>{{ Total[0] && Total[0].FinalPlanTotalAvg ? Total[0].FinalPlanTotalAvg : '' }}</td> 
                  <td :style="Total[1] && Total[1].FinalPlanTotalAvg ? color(getAverage(Total[1].FinalPlanTotalAvg,Total[0].FinalPlanTotalAvg)):''">
                    <v-icon small :style="Total[1] && Total[1].FinalPlanTotalAvg ?color(getAverage(Total[1].FinalPlanTotalAvg,Total[0].FinalPlanTotalAvg)):''">
                    {{Total[1] && Total[1].FinalPlanTotalAvg ? icon(getAverage(Total[1].FinalPlanTotalAvg,Total[0].FinalPlanTotalAvg)) :''}}</v-icon>
                    {{ Total[1] && Total[1].FinalPlanTotalAvg ? getAverage(Total[1].FinalPlanTotalAvg,Total[0].FinalPlanTotalAvg)  +'%' : ''  }}</td> 
                  <td>{{ Total[1] && Total[1].FinalPlanTotalAvg ? Total[1].FinalPlanTotalAvg : '' }}</td> 
                  <td v-if="Select == 'Quarterly'"  :style="Total[2] && Total[2].FinalPlanTotalAvg ? color(getAverage(Total[2].FinalPlanTotalAvg,Total[1].FinalPlanTotalAvg)):''">
                    <v-icon small :style="Total[2] && Total[2].FinalPlanTotalAvg ?color(getAverage(Total[2].FinalPlanTotalAvg,Total[1].FinalPlanTotalAvg)):''">
                    {{Total[2] && Total[2].FinalPlanTotalAvg ? icon(getAverage(Total[2].FinalPlanTotalAvg,Total[1].FinalPlanTotalAvg)) :''}}</v-icon>
                    {{ Total[2] && Total[2].FinalPlanTotalAvg ? getAverage(Total[2].FinalPlanTotalAvg,Total[1].FinalPlanTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[2] && Total[2].FinalPlanTotalAvg ? Total[2].FinalPlanTotalAvg : '' }}</td> 
                  
                  <td v-if="Select == 'Quarterly'"  :style="Total[3] && Total[3].FinalPlanTotalAvg ? color(getAverage(Total[3].FinalPlanTotalAvg,Total[2].FinalPlanTotalAvg)):''">
                    <v-icon small :style="Total[3] && Total[3].FinalPlanTotalAvg ?color(getAverage(Total[3].FinalPlanTotalAvg,Total[2].FinalPlanTotalAvg)):''">
                    {{Total[3] && Total[3].FinalPlanTotalAvg ? icon(getAverage(Total[3].FinalPlanTotalAvg,Total[2].FinalPlanTotalAvg)) :''}}</v-icon>
                    {{ Total[3] && Total[3].FinalPlanTotalAvg ? getAverage(Total[3].FinalPlanTotalAvg,Total[2].FinalPlanTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[3] && Total[3].FinalPlanTotalAvg ? Total[3].FinalPlanTotalAvg : '' }}</td>
                </tr>
                <tr class="bg2"  v-if="row.row19 && row.row19 == true">
                  <td v-if="selected[0].title == '加工依頼～上棟_日数平均'" :rowspan="selected.length" colspan="2">{{ '営業所合計' }}</td>
                  <td>{{ '加工依頼～上棟_日数平均' }}</td> 
                  <td>{{ '-' }}</td>
                  <td>{{ Total[0] && Total[0].JoutouTotalAvg ? Total[0].JoutouTotalAvg : '' }}</td> 
                  <td :style="Total[1] && Total[1].JoutouTotalAvg ? color(getAverage(Total[1].JoutouTotalAvg,Total[0].JoutouTotalAvg)):''">
                    <v-icon small :style="Total[1] && Total[1].JoutouTotalAvg ?color(getAverage(Total[1].JoutouTotalAvg,Total[0].JoutouTotalAvg)):''">
                    {{Total[1] && Total[1].JoutouTotalAvg ? icon(getAverage(Total[1].JoutouTotalAvg,Total[0].JoutouTotalAvg)) :''}}</v-icon>
                    {{ Total[1] && Total[1].JoutouTotalAvg ? getAverage(Total[1].JoutouTotalAvg,Total[0].JoutouTotalAvg)  +'%' : ''  }}</td> 
                  <td>{{ Total[1] && Total[1].JoutouTotalAvg ? Total[1].JoutouTotalAvg : '' }}</td> 
                  <td v-if="Select == 'Quarterly'"  :style="Total[2] && Total[2].JoutouTotalAvg ? color(getAverage(Total[2].JoutouTotalAvg,Total[1].JoutouTotalAvg)):''">
                    <v-icon small :style="Total[2] && Total[2].JoutouTotalAvg ?color(getAverage(Total[2].JoutouTotalAvg,Total[1].JoutouTotalAvg)):''">
                    {{Total[2] && Total[2].JoutouTotalAvg ? icon(getAverage(Total[2].JoutouTotalAvg,Total[1].JoutouTotalAvg)) :''}}</v-icon>
                    {{ Total[2] && Total[2].JoutouTotalAvg ? getAverage(Total[2].JoutouTotalAvg,Total[1].JoutouTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[2] && Total[2].JoutouTotalAvg ? Total[2].JoutouTotalAvg : '' }}</td> 
                  
                  <td v-if="Select == 'Quarterly'"  :style="Total[3] && Total[3].JoutouTotalAvg ? color(getAverage(Total[3].JoutouTotalAvg,Total[2].JoutouTotalAvg)):''">
                    <v-icon small :style="Total[3] && Total[3].JoutouTotalAvg ?color(getAverage(Total[3].JoutouTotalAvg,Total[2].JoutouTotalAvg)):''">
                    {{Total[3] && Total[3].JoutouTotalAvg ? icon(getAverage(Total[3].JoutouTotalAvg,Total[2].JoutouTotalAvg)) :''}}</v-icon>
                    {{ Total[3] && Total[3].JoutouTotalAvg ? getAverage(Total[3].JoutouTotalAvg,Total[2].JoutouTotalAvg)  +'%' : ''  }}</td> 
                  <td v-if="Select == 'Quarterly'" >{{ Total[3] && Total[3].JoutouTotalAvg ? Total[3].JoutouTotalAvg : '' }}</td>
                </tr>
              </tbody>
            </v-simple-table>
            </v-card>
          </div>
          <v-dialog v-model="dialog" height="500px" width="500px">
            <v-card>
              <v-toolbar dark style="  background-color: #01579b;" flat dense outlined>
                <h3>{{$t('plannerCodeRange.DisplayItems')}}</h3>
              </v-toolbar>
                <v-card-text>
                  <v-data-table 
                  :items-per-page="19" 
                  class="mt-2" 
                  v-model="hold"
                  :headers="header" 
                  :items="items" 
                  item-value="id"
                  :hide-default-footer="true" 
                  show-select
                  dense
                  outlined
                  >
                  </v-data-table>
               </v-card-text>
               <v-card-actions>
                  <v-btn @click="Category()" color="primary">{{ 'Select' }}</v-btn>
               </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
         <div v-if="!loading && arr.length == 0">
            <Logo />
        </div>
         <div v-else class="center" >
          <v-overlay :value="loading" color="#000000">
            <v-progress-circular
              :width="10"
              color="primary"
              indeterminate
              :size="70"
            >
            </v-progress-circular>
            <div>{{'ローディング中....'}}</div> 
          </v-overlay>
        </div>
    </div>
</template>
<script>
import CDatePicker from "../components/CDatePicker.vue";
import moment from 'moment';
import Swal from "sweetalert2";
import SalesOfficeClass from '../assets/class/salesOffice'
import PlannerCodeClass from '../assets/class/PlannersCode'
import QuarterlyClass from '../assets/class/SalesOffice/Quarterly'
import PlannerData from '../assets/class/PlannerData/PlannerData.js'
import Logo from "../components/Logo.vue";
    export default {
        components: { CDatePicker, Logo},
        data() {
            return {
              plannerData: new PlannerData(),
              tdHeight: '',
              salesOfficefunction: new SalesOfficeClass(),
              plannersCodefunction: new PlannerCodeClass(),
              quarterly: new QuarterlyClass(),
              plannerCodeRange: {
                    fromDate: moment().subtract(365,'days').startOf('day').format('YYYY-MM-DD'),
                    toDate: moment().format('YYYY-MM-DD'),
                    // fromDate:'2021-03-15',
                    // toDate:'2022-03-15',
                    Planner: {},
                },
              SalesOfficeArr:[],
              SalesLoading:false,
              user:{},
              Img:'../assets/Logo/SalesOffice.png',
              ImgJP:'../assets/Logo/SalesOfficeJP.png',
              planners:[],
              plannersLoading:false,
              datePicker: {},
              loading:false,
              SO:'',
              dialog:false,
              header:[ {text:'Select All',value:'title', sortable:false}],
              items:[
                  {id:1 ,title:'加工依頼数'},
                  {id:2 ,title:'追加変更数'},
                  {id:3 ,title:'1件あたりの追加変更数'},
                  {id:4 ,title:'CAD_依頼回数平均'},
                  {id:5 ,title:'即日CAD_依頼数平均'},
                  // {id:6 ,title:'設計受託契約前'},
                  {id:7 ,title:'提案電気図面依頼以降'},
                  {id:8 ,title:'着手図面依頼以降'},
                  {id:9 ,title:'電気図面_依頼回数平均'},
                  {id:10 ,title:'即日電気_依頼数平均'},
                  {id:11 ,title:'カラーパース_依頼回数平均'},
                  {id:12 ,title:'HP_依頼回数平均'},
                  {id:13 ,title:'構造事前検討（枠）_依頼回数平均'},
                  {id:14 ,title:'構造事前検討（軸）_依頼回数平均'},
                  {id:15 ,title:'ALVS_依頼回数平均'},
                  {id:16 ,title:'土地決定～加工依頼_日数平均'},
                  {id:17 ,title:'STOP～加工依頼_日数平均'},
                  {id:18 ,title:'着手準備~加工依頼_日数平均'},
                  {id:19 ,title:'加工依頼～上棟_日数平均'},
              ],
              selected:[],
              row:{},
              data:[],
              category:[{en:'Quarterly',jp:'四半期ごと'},{en:'Half-Year',jp:'半年ごと'}],
              Select:'',PeriodOftime:'',
              arr : [],
              arr2 : [],
              hold:[
                  {id:1 ,title:'加工依頼数'},
                  {id:2 ,title:'追加変更数'},
                  {id:3 ,title:'1件あたりの追加変更数'},
                  {id:4 ,title:'CAD_依頼回数平均'},
                  {id:5 ,title:'即日CAD_依頼数平均'},
                  // {id:6 ,title:'設計受託契約前'},
                  {id:7 ,title:'提案電気図面依頼以降'},
                  {id:8 ,title:'着手図面依頼以降'},
                  {id:9 ,title:'電気図面_依頼回数平均'},
                  {id:10 ,title:'即日電気_依頼数平均'},
                  {id:11 ,title:'カラーパース_依頼回数平均'},
                  {id:12 ,title:'HP_依頼回数平均'},
                  {id:13 ,title:'構造事前検討（枠）_依頼回数平均'},
                  {id:14 ,title:'構造事前検討（軸）_依頼回数平均'},
                  {id:15 ,title:'ALVS_依頼回数平均'},
                  {id:16 ,title:'土地決定～加工依頼_日数平均'},
                  {id:17 ,title:'STOP～加工依頼_日数平均'},
                  {id:18 ,title:'着手準備~加工依頼_日数平均'},
                  {id:19 ,title:'加工依頼～上棟_日数平均'},
              ],
              Total:[],Total2:[],
            }
        },
        methods: {
            MomentJP(val){
              return moment(val).format('YYYY年/M月');
            },
            tofixed2(num){
              return (Math.round((num ) * 10) / 10).toFixed(1)
            },
            getPlannerCode() {
              if( parseInt(moment.duration(moment(this.plannerCodeRange.toDate,"YYYY-MM-DD").diff(moment(this.plannerCodeRange.fromDate,"YYYY-MM-DD"))).asDays()) <= 365   ){
                this.user={}
                this.user=this.userInfo  
                if(!this.userInfo.User_Category){
                  this.planners=[]
                  this.plannerCodeRange.PlannerCode=this.userInfo.Emp_code
                  let obj={
                    PlannerCode:this.userInfo.Emp_code,
                    fromDate:this.plannerCodeRange.fromDate,
                    toDate:this.plannerCodeRange.toDate
                  }
                  // console.log(obj);
                  this.plannersLoading=true
                  this.plannersCodefunction.getplannersCode(this.aws,obj,this.apiKey).then((r)=>{
                    this.planners=r.data
                    let a = this.planners.findIndex(data=> data.EmployeeCode == obj.PlannerCode)
                      if(a == -1){
                        Swal.fire({
                          icon: "error",
                          title: "Error!",
                          text: `Planner ${this.userInfo.Emp_code} dont have Data`,
                        });
                        this.planners.push({
                          EmployeesData:`${this.userInfo.Emp_code +' - '+this.userInfo.Name}`,
                          EmployeeCode:this.userInfo.Emp_code,
                          EmployeeName:this.userInfo.Name
                        })
                      }
                    this.plannersLoading=r.loading
                  }) 
                }else{
                  this.SalesOfficeArr=[]
                  this.planners=[]
                  this.SalesLoading=true;
                  this.SO=''
                  let obj={
                    fromDate:this.plannerCodeRange.fromDate,
                    toDate:this.plannerCodeRange.toDate,
                    userCategory:this.userInfo.User_Category,
                    SalesOfficeName:this.userInfo.User_Category
                  }
                  this.salesOfficefunction.getSalesOffice(obj,this.aws,this.apiKey).then((r)=>{
                      this.SalesOfficeArr = r.data.filter((x) => x.EmployeeName !== null).sort((a, b) => a.sort - b.sort);
                      this.SalesLoading = r.data.loading;
                  })
                }
              }else{
                Swal.fire({
                  icon: "warning",
                  title: `${this.$i18n.locale =='ja'?'エラー' :'Warning' }`,
                  text: `${this.$i18n.locale =='ja'?' 開始日から終了日までの期間が1年以内の期間になるように入力し直してください。' :'Please re-enter so that the period from the start date to the end date is within one year.!'}`,
                });
                return false
              }
            },
            getPlannerData(){
            this.arr=[]
            if(this.SalesOfficeArr.length > 0){
              this.planners=[]
              this.planners = this.SalesOfficeArr.filter(r=>{
                if( r.SalesOfficeCode == this.SO.SalesOfficeCode){
                    r.EmployeesData = `${r.EmployeeCode +' - ' +r.EmployeeName}`
                    r.KakouIrai=0
                    r.data=[]
                    return r
                }
             })
            }
            this.arr=[...this.planners]
                this.datePicker = {
                  fromDate:this.plannerCodeRange.fromDate,
                  toDate:this.plannerCodeRange.toDate,
                  // BranchCode:this.SO.ContractExhibitionCode,
                  // BranchName:this.SO.ContractExhibitionName,
                  // EmployeeCode:this.SO.EmployeeCode,
                  // EmployeeName:this.SO.EmployeeName,
                  SalesOfficeCode:this.SO.SalesOfficeCode,
                  SalesOfficeName:this.SO.SalesOfficeName
                };
                this.loadData()
            },
            loadData(){
                if( parseInt(moment.duration(moment(this.datePicker.toDate,"YYYY-MM-DD").diff(moment(this.datePicker.fromDate,"YYYY-MM-DD"))).asDays()) <= 365   ){
                 if(this.SO==''||this.PeriodOftime =='' ){
                  Swal.fire({
                      icon: "warning",
                      // title: "Oops...",
                      title: `${this.$i18n.locale =='ja'?'エラー' :'Warning' }`,
                      text: `${this.$i18n.locale =='ja'?' すべてのフィールドに入力してください' :'Complete All Fields'}`,
                    });
                    this.loading = false;
                    return false
                  }
                  else{
                    this.loading=true
                    this.Select = this.PeriodOftime
                    this.quarterly.getQuarterly(this.plannerCodeRange,this.SO,this.aws,this.apiKey,this.planners,this.Select).then((res)=>{
                          this.data=res
                            this.data.data.forEach(res=>{
                                res.filter(x=>{
                                    let index = this.arr.findIndex(y=> x.EmployeeCode ==y.EmployeeCode)
                                    if(index > -1){
                                      this.arr[index].data.push(x)
                                    }else{
                                      this.arr[index].data.push({})
                                    } 
                                })
                            })
                            this.Category()
                            this.TotalFnc()
                            this.loading=false
                            this.$nextTick(() => {
                              this.plannerData.getTableHeight()
                              this.plannerData.scrollExist();
                            });
                        })  
                      // this.quarterly.getNationQrtly(this.plannerCodeRange,this.SO,this.aws,this.apiKey,this.aws2,this.apiKey2,this.planners,this.Select).then((res)=>{
                      //    this.arr2=res.data
                      //    if(this.arr2.length > 0 ){
                      //     this.TotalFnc2()
                      //    }
                      // })
                  }
              }else{
                Swal.fire({
                      icon: "warning",
                      title: `${this.$i18n.locale =='ja'?'エラー' :'Warning' }`,
                      text: `${this.$i18n.locale =='ja'?' 開始日から終了日までの期間が1年以内の期間になるように入力し直してください。' :'Please re-enter so that the period from the start date to the end date is within one year.!'}`,
                    });
                    this.loading = false;
                    return false
                  }
            },
            Category(){
              this.loading=true
              this.selected=[]
              this.row={}
              this.hold.forEach((x)=>{
                this.selected.push(x)
                this.row[`row${x.id}`] = true
              })
              this.$nextTick(() => {
                this.plannerData.scrollExist();
              });
              this.loading=false
              this.dialog=false
            },
            TotalFnc(){
              if(this.Select =='Quarterly'){
                this.Total=[{},{},{},{}]
              }
              else if(this.Select =='Half-Year'){
                this.Total=[{},{}]
              }
              let obj={}
              for(let i = 0; i <= this.Total.length-1; i++){
                obj ={
                    TotalKakouIrai:0,
                    TotalTH:0,
                    TotalTHAvg:0,
                    CadTotalAvg:0,
                    CadExpressTotalAvg:0,
                    CadContractTotalAvg:0,
                    CadPhase1TotalAvg:0,
                    CadPdrTotalAvg:0,
                    CadFinalTotalAvg:0,
                    DenkiTotalAvg:0,
                    DenkiExpressTotalAvg:0,
                    ExternalTotalAvg:0,
                    HPTotalAvg:0,
                    SI_WakuTotalAvg:0,
                    SI_JikuTotalAvg:0,
                    ALVSTotalAvg:0,
                    DecidedLandTotalAvg:0,
                    StopCheckTotalAvg:0,
                    FinalPlanTotalAvg:0,
                    JoutouTotalAvg:0,
                  }
                  // let HolderArr=[]
                this.arr.forEach(x=>{
                      // if( x.data[i] && x.data[i].KakouIrai ){
                        // HolderArr.push(x.data[i])
                        obj.TotalKakouIrai +=x.data[i] && x.data[i].KakouIrai ? x.data[i].KakouIrai : 0
                        obj.TotalTH += x.data[i] && x.data[i].TH ? x.data[i].TH : 0
                        obj.CadTotalAvg += x.data[i] && x.data[i].Cad ? parseFloat(x.data[i].Cad) : 0
                        obj.CadExpressTotalAvg += x.data[i] && x.data[i].CadExpress ? parseFloat(x.data[i].CadExpress) : 0
                        obj.CadContractTotalAvg += x.data[i] && x.data[i].CadContract ? parseFloat(x.data[i].CadContract) : 0
                        obj.CadPhase1TotalAvg += x.data[i] && x.data[i].CadPhase1 ? parseFloat(x.data[i].CadPhase1) : 0
                        obj.CadPdrTotalAvg += x.data[i] && x.data[i].CadPdr ? parseFloat(x.data[i].CadPdr) : 0
                        obj.CadFinalTotalAvg += x.data[i] && x.data[i].CadFinal ? parseFloat(x.data[i].CadFinal) : 0
                        obj.DenkiTotalAvg += x.data[i] && x.data[i].Denki ? parseFloat(x.data[i].Denki) : 0
                        obj.DenkiExpressTotalAvg += x.data[i] && x.data[i].DenkiExpress ? parseFloat(x.data[i].DenkiExpress) : 0
                        obj.ExternalTotalAvg += x.data[i] && x.data[i].ExternalPers ? parseFloat(x.data[i].ExternalPers) : 0
                        obj.HPTotalAvg += x.data[i] && x.data[i].HP ? parseFloat(x.data[i].HP) : 0
                        obj.SI_WakuTotalAvg += x.data[i] && x.data[i].SI_Waku ? parseFloat(x.data[i].SI_Waku) : 0
                        obj.SI_JikuTotalAvg += x.data[i] && x.data[i].SI_Jiku ? parseFloat(x.data[i].SI_Jiku) : 0
                        obj.ALVSTotalAvg += x.data[i] && x.data[i].ALVS ? parseFloat(x.data[i].ALVS) : 0
                        obj.DecidedLandTotalAvg += x.data[i] && x.data[i].DecidedLandDays ? parseFloat(x.data[i].DecidedLandDays) : 0
                        obj.StopCheckTotalAvg += x.data[i] && x.data[i].StopCheck ? parseFloat(x.data[i].StopCheck) : 0
                        obj.FinalPlanTotalAvg += x.data[i] && x.data[i].FinalPlan ? parseFloat(x.data[i].FinalPlan) : 0
                        obj.JoutouTotalAvg += x.data[i] && x.data[i].Joutou ? parseFloat(x.data[i].Joutou) : 0
                        
                      // }
                    })
                    obj.TotalTHAvg=this.tofixed2(obj.TotalTH/obj.TotalKakouIrai)
                    obj.CadTotalAvg=this.tofixed2(obj.CadTotalAvg / obj.TotalKakouIrai)
                    obj.CadExpressTotalAvg=this.tofixed2(obj.CadExpressTotalAvg / obj.TotalKakouIrai)
                    obj.CadContractTotalAvg=this.tofixed2(obj.CadContractTotalAvg / obj.TotalKakouIrai)
                    obj.CadPhase1TotalAvg=this.tofixed2(obj.CadPhase1TotalAvg / obj.TotalKakouIrai)
                    obj.CadPdrTotalAvg=this.tofixed2(obj.CadPdrTotalAvg / obj.TotalKakouIrai)
                    obj.CadFinalTotalAvg=this.tofixed2(obj.CadFinalTotalAvg / obj.TotalKakouIrai)
                    obj.DenkiTotalAvg=this.tofixed2(obj.DenkiTotalAvg / obj.TotalKakouIrai)
                    obj.DenkiExpressTotalAvg=this.tofixed2(obj.DenkiExpressTotalAvg / obj.TotalKakouIrai)
                    obj.ExternalTotalAvg=this.tofixed2(obj.ExternalTotalAvg / obj.TotalKakouIrai)
                    obj.HPTotalAvg=this.tofixed2(obj.HPTotalAvg / obj.TotalKakouIrai)
                    obj.SI_WakuTotalAvg=this.tofixed2(obj.SI_WakuTotalAvg / obj.TotalKakouIrai)
                    obj.SI_JikuTotalAvg=this.tofixed2(obj.SI_JikuTotalAvg / obj.TotalKakouIrai)
                    obj.ALVSTotalAvg=this.tofixed2(obj.ALVSTotalAvg / obj.TotalKakouIrai)
                    obj.DecidedLandTotalAvg=this.tofixed2(obj.DecidedLandTotalAvg / obj.TotalKakouIrai)
                    obj.StopCheckTotalAvg=this.tofixed2(obj.StopCheckTotalAvg / obj.TotalKakouIrai)
                    obj.FinalPlanTotalAvg=this.tofixed2(obj.FinalPlanTotalAvg / obj.TotalKakouIrai)
                    obj.JoutouTotalAvg=this.tofixed2(obj.JoutouTotalAvg / obj.TotalKakouIrai)

                this.Total[i]={...obj}
              }
              // console.log(this.arr);
            },
            TotalFnc2(){
              if(this.Select =='Quarterly'){
                this.Total2=[{},{},{},{}]
              }
              else if(this.Select =='Half-Year'){
                this.Total2=[{},{}]
              }
              let obj={}
              for(let i = 0; i <= this.Total.length-1; i++){
                obj ={
                    TotalKakouIrai:0,
                    TotalTH:0,
                    TotalTHAvg:0,
                    CadTotalAvg:0,
                    CadExpressTotalAvg:0,
                    CadContractTotalAvg:0,
                    CadPhase1TotalAvg:0,
                    CadPdrTotalAvg:0,
                    CadFinalTotalAvg:0,
                    DenkiTotalAvg:0,
                    DenkiExpressTotalAvg:0,
                    ExternalTotalAvg:0,
                    HPTotalAvg:0,
                    SI_WakuTotalAvg:0,
                    SI_JikuTotalAvg:0,
                    ALVSTotalAvg:0,
                    DecidedLandTotalAvg:0,
                    StopCheckTotalAvg:0,
                    FinalPlanTotalAvg:0,
                    JoutouTotalAvg:0,
                  }
                  this.arr2[i].filter(x=>{
                    obj.TotalKakouIrai +=x && x.KakouIrai ? x.KakouIrai : 0
                      obj.TotalTH += x && x.TH ? x.TH : 0
                      obj.CadTotalAvg += x && x.CadAvg ? parseFloat(x.CadAvg) : 0
                      obj.CadExpressTotalAvg += x && x.CadExpressAvg ? parseFloat(x.CadExpressAvg) : 0
                      obj.CadContractTotalAvg += x && x.CadContractAvg ? parseFloat(x.CadContractAvg) : 0
                      obj.CadPhase1TotalAvg += x && x.CadPhase1Avg ? parseFloat(x.CadPhase1Avg) : 0
                      obj.CadPdrTotalAvg += x && x.CadPdrAvg ? parseFloat(x.CadPdrAvg) : 0
                      obj.CadFinalTotalAvg += x && x.CadFinalAvg ? parseFloat(x.CadFinalAvg) : 0
                      obj.DenkiTotalAvg += x && x.DenkiAvg ? parseFloat(x.DenkiAvg) : 0
                      obj.DenkiExpressTotalAvg += x && x.DenkiExpressAvg ? parseFloat(x.DenkiExpressAvg) : 0
                      obj.ExternalTotalAvg += x && x.ExternalPersAvg ? parseFloat(x.ExternalPersAvg) : 0
                      obj.HPTotalAvg += x && x.HPAvg ? parseFloat(x.HPAvg) : 0
                      obj.SI_WakuTotalAvg += x && x.SI_WakuAvg ? parseFloat(x.SI_WakuAvg) : 0
                      obj.SI_JikuTotalAvg += x && x.SI_JikuAvg ? parseFloat(x.SI_JikuAvg) : 0
                      obj.ALVSTotalAvg += x && x.ALVSAvg ? parseFloat(x.ALVSAvg) : 0
                      obj.DecidedLandTotalAvg += x && x.DecidedLandDaysAvg ? parseFloat(x.DecidedLandDaysAvg) : 0
                      obj.StopCheckTotalAvg += x && x.StopCheckAvg ? parseFloat(x.StopCheckAvg) : 0
                      obj.FinalPlanTotalAvg += x && x.FinalPlanAvg ? parseFloat(x.FinalPlanAvg) : 0
                      obj.JoutouTotalAvg += x && x.JoutouAvg ? parseFloat(x.JoutouAvg) : 0
                  })
                    obj.TotalTHAvg=this.tofixed2(obj.TotalTH/obj.TotalKakouIrai)
                    obj.CadTotalAvg=this.tofixed2(obj.CadTotalAvg / this.arr2.length)
                    obj.CadExpressTotalAvg=this.tofixed2(obj.CadExpressTotalAvg / this.arr2.length)
                    obj.CadContractTotalAvg=this.tofixed2(obj.CadContractTotalAvg / this.arr2.length)
                    obj.CadPhase1TotalAvg=this.tofixed2(obj.CadPhase1TotalAvg / this.arr2.length)
                    obj.CadPdrTotalAvg=this.tofixed2(obj.CadPdrTotalAvg / this.arr2.length)
                    obj.CadFinalTotalAvg=this.tofixed2(obj.CadFinalTotalAvg / this.arr2.length)
                    obj.DenkiTotalAvg=this.tofixed2(obj.DenkiTotalAvg / this.arr2.length)
                    obj.DenkiExpressTotalAvg=this.tofixed2(obj.DenkiExpressTotalAvg / this.arr2.length)
                    obj.ExternalTotalAvg=this.tofixed2(obj.ExternalTotalAvg / this.arr2.length)
                    obj.HPTotalAvg=this.tofixed2(obj.HPTotalAvg / this.arr2.length)
                    obj.SI_WakuTotalAvg=this.tofixed2(obj.SI_WakuTotalAvg / this.arr2.length)
                    obj.SI_JikuTotalAvg=this.tofixed2(obj.SI_JikuTotalAvg / this.arr2.length)
                    obj.ALVSTotalAvg=this.tofixed2(obj.ALVSTotalAvg / this.arr2.length)
                    obj.DecidedLandTotalAvg=this.tofixed2(obj.DecidedLandTotalAvg / this.arr2.length)
                    obj.StopCheckTotalAvg=this.tofixed2(obj.StopCheckTotalAvg / this.arr2.length)
                    obj.FinalPlanTotalAvg=this.tofixed2(obj.FinalPlanTotalAvg / this.arr2.length)
                    obj.JoutouTotalAvg=this.tofixed2(obj.JoutouTotalAvg / this.arr2.length)
                this.Total2[i]={...obj}
              }
            },
            getAverage(val1,val2){
              let x =Math.ceil(((val1/val2)-1)*100)
              if(x ==Infinity || isNaN(x) || x==isNaN ||  x== isFinite){
                return 0
              }else{
                return x
              }
            },
            color(val){
             return val < 0 ? 'color:blue;': val > 0  ?'color:red;':'' 
            },
            icon(val){
              return val < 0 ? 'mdi-arrow-bottom-right': val > 0  ?'mdi-arrow-top-right':'mdi-arrow-right' 
            },
        },
        mounted () {
            this.getPlannerCode()
            window.addEventListener('resize', () =>{
              this.plannerData.getTableHeight()
              this.plannerData.scrollExist();
            })
        },
        computed: {
          monthRestriction() {
            return moment(this.plannerCodeRange.fromDate).add(12,'months').endOf('month').format('YYYY-MM-DD')
          },
          userInfo() {
            return this.$root.decryptedUser;
          }
        },
    }
</script>

<style lang="scss" scoped>

th{
  border-style: solid;
  border-width: 1px;
  border-color: black;
  background-color: #01579b !important;
  color:white !important;
  text-align: center !important;
  padding: 0px !important;  
}
tr{
  border-style: solid;
  border-width: 1px;
  border-color: black;
}
td{
  border-style: solid;
  border-width: 1px;
  border-color: black;
  text-align: center !important;

}
.bg3{
  background-color:#B0BEC5!important;
}
.bg2{
  background-color:#CFD8DC!important;
}
.bg{
  background-color:#E1F5FE!important;
}
.center {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  font-size: 18px;
}

.cnter{
    position:absolute;
    width: 300px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

</style>